// web-react-admin-lavabee\src\modules\videos\VideoEditor\components\ControlButtons.tsx
import React from 'react';
import { PlayArrow, Pause, VolumeUp, VolumeOff, Replay, Fullscreen, FullscreenExit, RotateRight } from "@mui/icons-material";
import { CustomTooltip } from './CustomTooltip';
import styles from '../styles/VideoEditor.module.scss';
import { observer } from "mobx-react-lite";
import { mainStore } from '../main';
import { runInAction } from 'mobx';

interface ControlButtonsProps {
  playing: boolean;
  isFullscreen: boolean;
  togglePlayPause: () => void;
  resetVideo: () => void;
  toggleFullscreen: () => void;
}

export const ControlButtons: React.FC<ControlButtonsProps> = observer(({
  playing,
  isFullscreen,
  togglePlayPause,
  resetVideo,
  toggleFullscreen
}) => {
  const { isMuted } = mainStore;

  return (
    <div className={styles.controls}>
      <div className={styles.controlsBackground}>
        <CustomTooltip title={`${playing ? "Pause" : "Play"}`}>
          <button className={styles.controlButton} onClick={togglePlayPause}>
            {playing ? <Pause /> : <PlayArrow />}
          </button>
        </CustomTooltip>
        <CustomTooltip title={
          <React.Fragment>
            <strong>{isMuted ? "Unmute" : "Mute"}</strong> - Affects download/post
          </React.Fragment>
        }>
          <div className={styles.muteButtonWrapper}>
            <button
              className={styles.controlButton}
              onClick={() => runInAction(() => mainStore.toggleMute())}
            >
              {isMuted ? <VolumeOff /> : <VolumeUp />}
            </button>
          </div>
        </CustomTooltip>
        <CustomTooltip title="Reset">
          <button className={styles.controlButton} onClick={resetVideo}>
            <Replay />
          </button>
        </CustomTooltip>
        <CustomTooltip title="Rotate 90°">
          <button className={styles.controlButton} onClick={() => {
            runInAction(() => {
              mainStore.handleRotate();
            });
          }}>
            <RotateRight />
          </button>
        </CustomTooltip>
        <CustomTooltip title={isFullscreen ? "Exit Full Screen" : "Full Screen"}>
          <button className={styles.controlButton} onClick={toggleFullscreen}>
            {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
          </button>
        </CustomTooltip>
      </div>
    </div>
  );
});