// web-react-admin-lavabee\src\modules\videos\VideoEditor\components\useTimelineDrag.ts
import { RefObject, useRef } from "react";
import { usePointerDrag } from "react-use-pointer-drag";
import { Time } from "../types";
import { clamp } from "../helpers";
import { mainStore } from "../main";

interface UseTimelineDragProps {
  video: HTMLVideoElement;
  time: Time;
  onChange: (time: Time) => void;
  timelineRef: RefObject<HTMLDivElement>;
  setCurrentTime: (time: number) => void;
  MIN_DURATION: number;
}

export const useTimelineDrag = ({
  video,
  time,
  onChange,
  timelineRef,
  setCurrentTime,
  MIN_DURATION,
}: UseTimelineDragProps) => {
  const ignoreTimeUpdatesRef = useRef(false);

  const { dragProps, dragState } = usePointerDrag<{
    direction: string;
    time?: Time;
    currentTime?: number;
    paused: boolean;
  }>({
    stopPropagation: true,
    pointerDownStopPropagation: true,
    onStart: () => {
      video.pause();
    },

    onClick: ({ state, x }) => {
      if (state.direction !== "move") {
        return;
      }

      const rect = timelineRef.current!.getBoundingClientRect();
      const relativeX = clamp((x - rect.left) / rect.width, 0, 1) * video.duration;
      const currentTime = clamp(relativeX, state.time![0], state.time![1]);
      setCurrentTime(currentTime);
      video.currentTime = currentTime;
    },

    onMove: ({ x, deltaX, state }) => {
      ignoreTimeUpdatesRef.current = true;
      const rect = timelineRef.current!.getBoundingClientRect();

      let relativeX = clamp((x - rect.left) / rect.width, 0, 1) * video.duration;
      const newTime: Time = [...time];

      switch (state.direction) {
      case "move":
        {
          relativeX = clamp(
            (deltaX / rect.width) * video.duration,
            -1 * state.time![0],
            video.duration - state.time![1]
          );
          newTime[0] = state.time![0] + relativeX;
          newTime[1] = state.time![1] + relativeX;

          const currentTime = clamp(video.currentTime, newTime[0], newTime[1]);
          setCurrentTime(currentTime);
          video.currentTime = currentTime;
        }
        break;

      case "left":
        newTime[0] = Math.min(relativeX, Math.max(newTime[1] - MIN_DURATION, 0));
        video.currentTime = newTime[0] + 0.01;
        break;

      case "right":
        newTime[1] = Math.max(relativeX, Math.min(newTime[0] + MIN_DURATION, video.duration));
        video.currentTime = newTime[1];
        break;

      case "seek":
        {
          const currentTime = clamp(relativeX, state.time![0], state.time![1]);
          setCurrentTime(currentTime);
          video.currentTime = currentTime;
        }
        break;
      }

      onChange(newTime);
    },

    onEnd: ({ state }) => {
      ignoreTimeUpdatesRef.current = false;
      if (typeof state.currentTime !== "undefined") {
        video.currentTime = state.currentTime;
      }

      if (!state?.paused) {
        video.play();
      }
    }
  });

  return { dragProps, dragState, ignoreTimeUpdatesRef };
};