// web-react-admin-lavabee\src\modules\videos\EditVideo\components\VideoModals.tsx
import React from 'react';
import PostVideoModal from "../../PostVideoModal";
import SuccessModal from '../../SuccessModal';
import DeleteVideoModal from "../../DeleteVideoModal";

interface VideoModalsProps {
  modalStates: {
    isPostModalVisible: boolean;
    setPostModalVisible: (value: boolean) => void;
    postSubmittedSuccess: boolean;
    setPostSubmittedSuccess: (value: boolean) => void;
    openDelete: boolean;
    setOpenDelete: (value: boolean) => void;
    deleteVideoSuccess: boolean;
    setDeleteVideoSuccess: (value: boolean) => void;
    saveEditSuccess: boolean;
    setSaveEditSuccess: (value: boolean) => void;
    saveEditError: boolean;
    setSaveEditError: (value: boolean) => void;
  };
  navigate: (path: string) => void;
  dogId: string;
  dogName: string | undefined;
  postVideoUrl: string;
  setPostVideoUrl: (url: string) => void;
  thumbnailUrl: string | undefined;
  videoId: string | undefined;
}

const VideoModals: React.FC<VideoModalsProps> = ({
  modalStates,
  navigate,
  dogId,
  dogName,
  postVideoUrl,
  setPostVideoUrl,
  thumbnailUrl,
  videoId
}) => (
  <>
    <PostVideoModal
      isVisible={modalStates.isPostModalVisible}
      onClose={() => {
        modalStates.setPostModalVisible(false);
        setPostVideoUrl("");
      }}
      onSuccessPost={() => {
        modalStates.setPostModalVisible(false);
        setPostVideoUrl("");
        modalStates.setPostSubmittedSuccess(true);
      }}
      dogId={dogId}
      selectedDogName={dogName}
      videoSrc={postVideoUrl}
      thumbnail_url={thumbnailUrl}
    />
    <SuccessModal
      isVisible={modalStates.postSubmittedSuccess}
      onClose={() => {
        modalStates.setPostSubmittedSuccess(false);
        navigate("/posts/approve");
      }}
      title="Post Submitted Successfully"
      message="Your post has been successfully submitted and will be processed shortly."
      isPost={true}
    />
    <DeleteVideoModal
      isVisible={modalStates.openDelete}
      onClose={() => {
        modalStates.setOpenDelete(false);
      }}
      onSuccess={() => {
        modalStates.setDeleteVideoSuccess(true);
      }}
      videoId={videoId}
    />
    <SuccessModal
      isVisible={modalStates.deleteVideoSuccess}
      onClose={() => {
        modalStates.setDeleteVideoSuccess(false);
        navigate("/videos");
      }}
      title="Video Deleted Successfully"
      message="The video has been successfully deleted."
    />
    <SuccessModal
      isVisible={modalStates.saveEditSuccess}
      onClose={() => modalStates.setSaveEditSuccess(false)}
      title="Edits Saved Successfully"
      message="Your video edits have been saved successfully."
    />
    <SuccessModal
      isVisible={modalStates.saveEditError}
      onClose={() => modalStates.setSaveEditError(false)}
      title="Error Saving Edits"
      message="There was an error saving your video edits. Please try again."
      isError={true}
    />
  </>
);

export default VideoModals;