// web-react-admin-lavabee\src\modules\videos\VideoEditor\components\Timeline.tsx
import React, { forwardRef, useCallback } from 'react';
import { Typography } from "@mui/material";
import clsx from "clsx";
import { humanTime } from '../helpers';
import styles from '../styles/VideoEditor.module.scss';
import { observer } from 'mobx-react-lite';
import { mainStore } from '../main';

interface TimelineProps {
  video: HTMLVideoElement;
  currentTime: number;
  progress: number;
  dragProps: any;
  dragState: any;
}

export const Timeline = observer(forwardRef<HTMLDivElement, TimelineProps>(({
  video,
  currentTime,
  progress,
  dragProps,
  dragState
}, ref) => {
  const time = mainStore.transform.time || [0, video?.duration];

  const getRangeStyle = useCallback(() => ({
    left: `${(time[0] / video?.duration) * 100}%`,
    right: `${100 - (time[1] / video?.duration) * 100}%`
  }), [time, video?.duration]);

  return (
    <div className={styles.timelineContainer}>
      <Typography variant="body2" className={styles.timeLabel}>
        {humanTime(time[0])}
      </Typography>
      <div className={styles.timeline} ref={ref}>
        <div
          className={styles.range}
          style={getRangeStyle()}
          {...dragProps({
            direction: "move",
            time,
            paused: video?.paused
          })}
        >
          <div
            className={clsx(styles.handle, styles.handleLeft, {
              [styles.active]: dragState?.direction === "left"
            })}
            data-time={humanTime(time[0])}
            {...dragProps({
              direction: "left",
              currentTime,
              paused: video?.paused
            })}
          />
          <div
            className={clsx(styles.handle, styles.handleRight, {
              [styles.active]: dragState?.direction === "right"
            })}
            data-time={humanTime(time[1])}
            {...dragProps({
              direction: "right",
              currentTime,
              paused: video?.paused
            })}
          />
        </div>
        <div
          className={styles.progress}
          style={{ width: `${progress}%` }}
        />
        <div
          className={clsx(styles.current, {
            [styles.active]: dragState?.direction === "seek"
          })}
          style={{ left: `${(currentTime / video?.duration) * 100}%` }}
          {...dragProps({
            direction: "seek",
            time,
            paused: video?.paused
          })}
        />
      </div>
      <Typography variant="body2" className={styles.timeLabel}>
        {humanTime(time[1])}
      </Typography>
    </div>
  );
}));

Timeline.displayName = 'Timeline';