// web-react-admin-lavabee\src\modules\videos\VideoEditor\main.ts
import {FFmpeg} from "@ffmpeg/ffmpeg";
import {
  action,
  makeAutoObservable,
  observable,
  reaction,
  runInAction
} from "mobx";
import {get, set} from "idb-keyval";

import {VideoTransform} from "./types";

const canUseMT = "SharedArrayBuffer" in window;
const ffmpegVersion = "0.12.6";
const ffmpegName = canUseMT ? "core-mt" : "core";
const ffmpegWorker = canUseMT ? "ffmpeg-core.worker.js" : undefined;
const ffmpegBaseURL = `https://unpkg.com/@ffmpeg/${ffmpegName}@${ffmpegVersion}/dist/esm`;
export interface VideoPresets {
  trimStart: number;
  trimEnd: number;
  rotation: number;
}

async function retrieveBlob(
  url: string,
  type: string,
  onProgress?: (progress: number) => void
) {
  let buffer = await get(url);
  if (!buffer) {
    const response = await fetch(url);
    const reader = response.body?.getReader();
    if (!reader) {
      throw new Error(`Unable to fetch: ${url}`);
    }

    const contentLength = +response.headers.get("Content-Length")!;
    let receivedLength = 0;
    const chunks = [];

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const {done, value} = await reader.read();

      if (done) {
        break;
      }

      chunks.push(value);
      receivedLength += value.length;
      onProgress?.(receivedLength / contentLength);
    }

    buffer = await new Blob(chunks).arrayBuffer();

    try {
      set(url, buffer);
      // console.log(`Saved to IndexedDB: ${url}`);
    } catch {
      //
    }
  } else {
    // console.log(`Loaded from IndexedDB: ${url}`);
  }

  const blob = new Blob([buffer], {type});
  return URL.createObjectURL(blob);
}

class FfmpegStore {
  loaded = false;
  loadProgress = 0;
  ffmpeg = new FFmpeg();

  running = false;
  execProgress = 0;
  outputUrl: string | undefined = undefined;
  output: string = "";
  log: string = "";

  private originalDuration: number = 0;
  private trimmedDuration: number = 0;
  private progressListeners: ((progress: number) => void)[] = [];

  onLoadCallback: (() => void) | undefined = undefined;

  constructor() {
    makeAutoObservable(this);

    this.ffmpeg.on("log", (e) => {
      // console.log(e);
      runInAction(() => {
        this.output = e.message;
        this.log += `${e.message}\n`;
      });
    });

    this.ffmpeg.on("progress", (e) => {
      runInAction(() => {
        this.execProgress = e.progress;
        this.progressListeners.forEach((listener) => listener(e.progress));
      });
    });
  }

  async load() {
    // toBlobURL is used to bypass CORS issue, urls with the same
    // domain can be used directly.
    await this.ffmpeg.load({
      coreURL: await retrieveBlob(
        `${ffmpegBaseURL}/ffmpeg-core.js`,
        "text/javascript"
      ),
      wasmURL: await retrieveBlob(
        `${ffmpegBaseURL}/ffmpeg-core.wasm`,
        "application/wasm",
        (progress) => {
          runInAction(() => {
            this.loadProgress = progress;
          });
        }
      ),
      workerURL: ffmpegWorker
        ? await retrieveBlob(
            `${ffmpegBaseURL}/${ffmpegWorker}`,
            "text/javascript"
          )
        : undefined
    });

    runInAction(() => {
      this.loadProgress = 1;
      this.loaded = true;

      if (this.onLoadCallback) {
        this.onLoadCallback();
        this.onLoadCallback = undefined;
      }
    });
  }

  on(event: "progress", callback: (progress: number) => void) {
    if (event === "progress") {
      const adjustedCallback = (progress: number) => {
        const adjustedProgress =
          (progress * this.originalDuration) / this.trimmedDuration;
        callback(adjustedProgress);
      };

      this.progressListeners.push(adjustedCallback);
    }
  }

  off(event: "progress", callback: (progress: number) => void) {
    if (event === "progress") {
      this.progressListeners = this.progressListeners.filter(
        (listener) => listener !== callback
      );
    }
  }

  async exec(file: File, args: string[], abortSignal?: AbortSignal) {
    this.running = true;
    this.execProgress = 0;
    this.output = "";

    try {
      await this.ffmpeg.writeFile(
        "input",
        new Uint8Array(await file.arrayBuffer())
      );

      this.originalDuration = mainStore.video?.duration || 0;
      const start = mainStore.transform.time?.[0] || 0;
      const end = mainStore.transform.time?.[1] || this.originalDuration;
      this.trimmedDuration = end - start;
      await this.ffmpeg.exec([...args, "output.mp4"], 100000, {
        signal: abortSignal
      });

      const data = (await this.ffmpeg.readFile("output.mp4")) as Uint8Array;

      return new File([data.buffer], "output.mp4", {type: "video/mp4"});
    } finally {
      try {
        await this.ffmpeg.deleteFile("output.mp4");
      } catch {
        //
      }

      runInAction(() => {
        this.running = false;
      });
    }
  }

  cancel() {
    this.ffmpeg.terminate();
    this.load();
  }
}

class MainStore {
  file: File | undefined = undefined;
  fileLoading = false;
  transform: VideoTransform = {
    time: [0, 0],
    rotation: 0
  };
  originalPresets: VideoPresets = {
    trimStart: 0,
    trimEnd: 0,
    rotation: 0
  };
  isMuted: boolean = false;

  ffmpeg = new FfmpegStore();

  step = 0;
  video: HTMLVideoElement | undefined = undefined;

  brandId: string = "";

  constructor() {
    makeAutoObservable(this);
    this.ffmpeg.load();

    reaction(
      () => [this.step],
      () => this.video?.pause()
    );
  }

  reset() {
    if (this.originalPresets) {
      this.transform = {
        ...this.transform,
        time: [
          this.originalPresets.trimStart || 0,
          this.originalPresets.trimEnd || this.video?.duration || 0
        ],
        rotation: this.originalPresets.rotation || 0
      };
    } else {
      this.transform = {
        time: [0, 0],
        rotation: 0
      };
    }

    if (this.video) {
      this.video.pause();
      this.video.currentTime = this.originalPresets?.trimStart || 0;
    }
  }

  setOriginalPresets(presets: any) {
    this.originalPresets = presets;
  }

  setRotation(rotation: number) {
    this.transform.rotation = rotation;
  }

  handleRotate() {
    this.transform.rotation = (this.transform.rotation + 90) % 360;
  }

  toggleMute() {
    this.isMuted = !this.isMuted;
    if (this.video) {
      this.video.muted = this.isMuted;
    }
  }

  async loadVideo(file: File, brandId: string) {
    this.video?.pause();
    this.video = undefined;
    this.file = file;
    this.fileLoading = true;
    this.ffmpeg.onLoadCallback = undefined;
    this.reset();

    const video = document.createElement("video");
    if (!video.canPlayType(file.type)) {
      const remux = async () => {
        const newFile = await this.ffmpeg.exec(file, [
          "-c:v",
          "copy",
          "-c:a",
          "copy"
        ]);
        if (newFile) {
          this.loadVideo(newFile, brandId);
        } else {
          // TODO: Error handling.
          runInAction(() => {
            this.fileLoading = false;
          });
        }
      };
      if (this.ffmpeg.loaded) {
        remux();
      } else {
        this.ffmpeg.onLoadCallback = remux;
      }
      return;
    }

    video.setAttribute("playsinline", "");
    video.preload = "metadata";
    video.autoplay = false;

    // Required when using a Service Worker on iOS Safari.
    video.crossOrigin = "anonymous";

    video.addEventListener("loadedmetadata", () => {
      runInAction(() => {
        video.currentTime = 0.01;
        this.video = video;
        // Inicializar transform.time con la duración completa del video
        this.transform.time = [0, video.duration];
      });
    });

    video.addEventListener("canplay", () => {
      if (this.fileLoading) {
        this.fileLoading = false;
        this.step = 1;
      }
    });

    video.addEventListener("ended", () => {
      const start = this.transform.time?.[0] || 0;
      video.currentTime = start;
    });

    video.addEventListener("timeupdate", () => {
      const start = this.transform.time?.[0] || 0;
      const end = this.transform.time?.[1] || video.duration;

      if (video.currentTime > end) {
        video.currentTime = start;
      } else if (video.currentTime < start - 1) {
        video.currentTime = start;
      }
    });

    this.brandId = brandId;
    video.src = URL.createObjectURL(file);
  }
}

export const mainStore = new MainStore();
