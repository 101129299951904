// web-react-admin-lavabee\src\modules\videos\VideoEditor\components\ClipDuration.tsx
import React from 'react';
import { Typography } from "@mui/material";
import { humanTimeV2 } from '../helpers';
import styles from '../styles/VideoEditor.module.scss';

interface ClipDurationProps {
  start: number;
  end: number;
}

export const ClipDuration: React.FC<ClipDurationProps> = ({ start, end }) => (
  <Typography variant="body2" className={styles.clipDuration}>
    Clip Duration: {humanTimeV2(end - start)}
  </Typography>
);