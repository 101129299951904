// web-react-admin-lavabee\src\modules\Subscription\SubmitEditPlanDialog.styles.ts
import { styled } from "@mui/material/styles";
import { Dialog, Box, Card, CardMedia } from "@mui/material";

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    overflow: 'hidden',
  },
  '& .MuiDialogTitle-root': {
    backgroundColor: theme.palette.background.default,
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  '& .MuiDialogActions-root': {
    backgroundColor: theme.palette.background.default,
    position: 'sticky',
    bottom: 0,
    zIndex: 1,
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 200px)',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.background.default,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: '4px',
    },
  },
}));

export const ImageUploadBox = styled(Box)(({ theme }) => ({
  border: `2px dashed ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  textAlign: 'center',
  '& .MuiCardMedia-root': {
    cursor: 'pointer',
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

export const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  height: 140,
  backgroundSize: 'contain',
}));

export const PreviewCard = styled(Card)<{ type?: string }>(({ theme, type }) => ({
  margin: 'auto',
  marginBottom: theme.spacing(2),
  backgroundColor: type === "plan" ? "#F7FBEE" : undefined,
}));

export const FeatureItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
}));

export const IconWrapper = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '4px',
  marginRight: theme.spacing(2),
  position: 'relative',
  overflow: 'hidden',
}));

export const PriceWrapper = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

export const PreviewHeader = styled(Box)<{ backgroundColor?: string }>
  (({ theme, backgroundColor }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '1rem',
    backgroundColor: backgroundColor || theme.palette.primary.main,
  }));

export const ColorPickerWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));
