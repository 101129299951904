import { useState, useEffect } from 'react';

export const useVideoProgress = (video: HTMLVideoElement) => {
  const [currentTime, setCurrentTime] = useState(video.currentTime);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const updateProgress = () => {
      setCurrentTime(video.currentTime);
      setProgress((video.currentTime / video.duration) * 100);
    };

    video.addEventListener('timeupdate', updateProgress);
    return () => {
      video.removeEventListener('timeupdate', updateProgress);
    };
  }, [video]);

  return { currentTime, progress, setCurrentTime, setProgress };
};
