import {useRoutes} from "react-router-dom";
import router from "../../router";

// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";

import {Button, CssBaseline, Grid, Typography} from "@mui/material";
import ThemeProvider from "../../styles/theme/ThemeProvider";
import noInternetGif from "../../assets/images/nointernet.gif";
import ServerErrorGif from "../../assets/images/servererror.gif";
import React from "react";

class ErrorBoundary extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {}
  handleClick = () => {
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <Grid
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100%"}
          height={"100vh"}
        >
          <Grid display={"flex"} flexDirection={"column"}>
            <Typography variant="h2" my={"20px"}>
              Something went wrong.
            </Typography>

            <Button onClick={this.handleClick} variant="contained">
              Reload
            </Button>
          </Grid>
        </Grid>
      );
    }

    return this.props.children;
  }
}

function App() {
  const content = useRoutes(router);

  return (
    <ThemeProvider>
      {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
      <CssBaseline />
      <img src={ServerErrorGif} alt="No internet" style={{display: "none"}} />
      <img src={noInternetGif} alt="No internet" style={{display: "none"}} />
      <ErrorBoundary>{content}</ErrorBoundary>
      {/* </LocalizationProvider> */}
    </ThemeProvider>
  );
}
export default App;
