// // web-react-admin-lavabee\src\modules\videos\EditVideo\components\LoadingState.tsx
import React from 'react';
import { Card, CardContent, Box, Stack, CircularProgress, Typography } from "@mui/material";
import dog from "../../../../assets/images/loadingDog.png";

const LoadingState = () => (
  <Card
    variant="outlined"
    style={{
      flex: 2.2,
      display: "flex",
      height: "83vh",
      justifyContent: "center",
      alignItems: "center",
      margin: "20px 5px 20px 20px"
    }}
  >
    <CardContent>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <img src={dog} alt="dog" />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          mt={1}
        >
          <CircularProgress style={{ marginRight: 20 }} />
          <Typography variant="h6" className="my-2">
            Please wait while we are loading
          </Typography>
        </Stack>
      </Box>
    </CardContent>
  </Card>
);

export default LoadingState;