/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  Divider,
  Box,
  CircularProgress,
  debounce,
  TextField,
  Autocomplete,
  InputAdornment
} from "@mui/material";
import PreviewVideo from "./PreviewVideo";
import {useLazyQuery, useQuery} from "@apollo/client";
import {GET_ALL_LOCATIONS, GET_ALL_VIDEO} from "../../shared/graphQL/video";
import dog from "../../assets/images/loadingDog.png";
import {GET_PRODUCTS} from "../../shared/graphQL/dog/queries";
import DatePickerModal from "./DatePickerModal";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const events = [
  "morning_event",
  "morning_feed_event",
  "morning_play_outside_event",
  "afternoon_feed_event",
  "afternoon_play_outside_event",
  "wash_event",
  "sleep_event"
];

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * events.length + ITEM_PADDING_TOP,
      width: 230,
      padding: 4
    }
  }
};

type arr = {
  title: any;
  data: any[];
};

const untagged = {_id: "92873d32lj3223", name: "Untagged"};

function Videos() {
  const LIMIT = 40;
  const listInnerRef = useRef();

  const [products, setProducts] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [dogList, setDogList] = useState([]);
  const [dogName, setDogName] = useState([]);
  const [locationName, setLocationName] = useState([]);
  const [filteredLocationData, setFilteredLocationData] = useState([]);
  const [dateRange, setDateRange] = useState<any>([null, null]);
  const [dateRangeModal, setDateRangeModal] = useState<any>(false);
  const [eventTypes, setEventTypes] = useState([]);
  const [event, setEvents] = useState<any>(events);
  const [value, setValue] = React.useState([null, null]);

  const [getProducts, {data: getAllProducts, loading: productLoading}] =
    useLazyQuery(GET_PRODUCTS, {fetchPolicy: "no-cache"});

  const {data: allCameraLocation, loading: cameraLoading} = useQuery(
    GET_ALL_LOCATIONS,
    {
      fetchPolicy: "no-cache"
    }
  );

  useEffect(() => {
    if (allCameraLocation?.findAllCameraLocation?.camera_locations) {
      setFilteredLocationData(
        allCameraLocation?.findAllCameraLocation?.camera_locations
      );
    }
  }, [allCameraLocation]);

  const fetchLocationList = React.useMemo(
    () =>
      debounce((request: {input: string}) => {
        if (allCameraLocation?.findAllCameraLocation?.camera_locations) {
          const filterData =
            allCameraLocation?.findAllCameraLocation?.camera_locations.filter(
              (item) => {
                return item?.location
                  ?.toLowerCase()
                  .includes(request?.input?.toLowerCase());
              }
            );

          setFilteredLocationData(filterData);
        }
      }, 400),
    []
  );

  const fetchDogList = React.useMemo(
    () =>
      debounce((request: {input: string}) => {
        getProducts({
          variables: {
            input: {
              pageDto: {page: 1, limit: 20},
              search: request?.input
            }
          }
        });
      }, 400),
    []
  );

  const handleDateRangeChange = (newValue) => {
    localStorage.setItem("dateRange", JSON.stringify(newValue));
    setDateRange(newValue);
    setDateRangeModal(false);
  };

  const handleEventTypeChange = (event: any, value) => {
    if (value) localStorage.setItem("eventTypes", JSON.stringify(value));
    setEventTypes(typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    fetchDogList({input: ""});

    let location: any = localStorage.getItem("location");
    let dogsSelected: any = localStorage.getItem("dogsSelected");
    let dateRangeStorage: any = localStorage.getItem("dateRange");
    let eventTypes: any = localStorage.getItem("eventTypes");

    try {
      location = JSON.parse(location);
    } catch (e) {
      location = [];
    }
    try {
      dogsSelected = JSON.parse(dogsSelected);
    } catch (e) {
      dogsSelected = [];
    }
    try {
      eventTypes = JSON.parse(eventTypes);
    } catch (e) {
      eventTypes = [];
    }
    try {
      dateRangeStorage = JSON.parse(dateRangeStorage);
      if (dateRangeStorage[0] === null || dateRangeStorage[1] === null) {
        dateRangeStorage = [null, null];
      } else {
        dateRangeStorage = [
          moment(dateRangeStorage[0]),
          moment(dateRangeStorage[1])
        ];
      }
    } catch (e) {
      dateRangeStorage = [null, null];
    }

    if (location) {
      setLocationName([...location]);
    }

    if (dogsSelected) {
      setDogName([...dogsSelected]);
    }

    if (dateRangeStorage) {
      setDateRange(dateRangeStorage);
    }

    if (eventTypes) {
      setEventTypes(eventTypes);
    }
  }, []);

  useEffect(() => {
    if (getAllProducts?.retrieveProductsList?.productsDataList)
      setDogList((prev) => [
        ...JSON.parse(getAllProducts?.retrieveProductsList?.productsDataList),
        untagged
      ]);
  }, [getAllProducts]);

  ///asdf
  const navigate = useNavigate();

  const {data: initialData, loading: initialLoading} = useQuery(GET_ALL_VIDEO, {
    variables: {
      input: {
        page: 1,
        limit: LIMIT,
        dog_name: dogName.reduce(
          (acc, val) => (acc ? `${acc};` : acc) + val?.name,
          ""
        ),
        time_start: dateRange[0],
        time_end: dateRange[1],

        location: locationName.reduce(
          (acc, val) => (acc ? `${acc};` : acc) + val?.location,
          ""
        ),
        event: eventTypes.reduce(
          (acc, val) => (acc ? `${acc};` : acc) + val,
          ""
        )
      }
    }
  });

  const [getAllVideo, {data, loading}] = useLazyQuery(GET_ALL_VIDEO);

  const loadMoreData = (dogNameFilter, locationFilter) => {
    if (currentPage <= totalPage) {
      getAllVideo({
        variables: {
          input: {
            page: currentPage + 1,
            limit: LIMIT,
            dog_name: dogNameFilter.reduce(
              (acc, val) => (acc ? `${acc};` : acc) + val?.name,
              ""
            ),
            time_start: dateRange[0],
            time_end: dateRange[1],
            location: locationFilter.reduce(
              (acc, val) => (acc ? `${acc};` : acc) + val?.location,
              ""
            ),
            event: eventTypes.reduce(
              (acc, val) => (acc ? `${acc};` : acc) + val,
              ""
            )
          }
        }
      });

      setCurrentPage((page) => page + 1);
    }
  };

  const onScroll = () => {
    if (listInnerRef.current) {
      const {scrollTop, scrollHeight, clientHeight} = listInnerRef.current;

      const SCROLL_HEIGHT = scrollHeight - 50;

      if (scrollTop + clientHeight >= SCROLL_HEIGHT) {
        loadMoreData(dogName, locationName);
      }
    }
  };

  function groupByDate(videos) {
    function formatDate(dateString) {
      const date = new Date(dateString);
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(date.getDate()).padStart(2, "0")}`;
    }

    const dateMap = videos.reduce((acc, video) => {
      const date = formatDate(video.createdAt);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(video);
      return acc;
    }, {});

    const result = [];
    for (let date in dateMap) {
      result.push({
        title: date,
        data: dateMap[date]
      });
    }

    return result;
  }

  useEffect(() => {
    if (initialData) {
      const res = groupByDate(initialData?.findAllVideo?.videos);
      setProducts(res);
      setTotalPage(initialData?.findAllVideo?.pages);
    }
  }, [initialData]);

  useEffect(() => {
    if (data) {
      const res = groupByDate(data?.findAllVideo?.videos);
      const lastProduct = products[products.length - 1];
      const paginationFirstProduct = res[0];

      if (paginationFirstProduct?.title === lastProduct?.title) {
        res[0] = {
          ...paginationFirstProduct,
          data: [...lastProduct?.data, ...paginationFirstProduct?.data]
        };

        setProducts((itemVideos) => [...itemVideos?.slice(0, -1), ...res]);
      } else {
        setProducts((itemVideos) => [...itemVideos, ...res]);
      }
    }
  }, [data]);

  const handleClick = (key: string) => {
    navigate(`/edit/${key}`);
  };

  const handleClearRange = () => {
    localStorage.setItem("dateRange", JSON.stringify([null, null]));
    setDateRange([null, null]);
    setDateRangeModal(false);
  };

  const getSectionTitle = (index: number) => {
    const format = "DD-MM-YYYY";
    const today = moment(moment().format(format), format);

    if (index === 0 && today.isSame(products[index]?.title)) {
      return (
        <>
          <Typography variant="h6">Today</Typography>
          <Typography variant="body2">
            {moment(products[index]?.title).format("DD-MM-YYYY")}
          </Typography>
        </>
      );
    }

    if (
      index === 0 &&
      today.subtract(1, "days").isSame(products[index]?.title)
    ) {
      return (
        <>
          <Typography variant="h6">Yesterday</Typography>
          <Typography variant="body2">
            {moment(products[index]?.title).format("DD-MM-YYYY")}
          </Typography>
        </>
      );
    }

    if (
      index === 1 &&
      today.subtract(1, "days").isSame(products[index]?.title)
    ) {
      return (
        <>
          <Typography variant="h6">Yesterday</Typography>
          <Typography variant="body2">
            {moment(products[index]?.title).format("DD-MM-YYYY")}
          </Typography>
        </>
      );
    }

    return (
      <Typography variant="body2">
        {moment(products[index]?.title).format("DD-MM-YYYY")}
      </Typography>
    );
  };

  return (
    <>
      <Grid container p={2}>
        <Grid item>
          <Typography variant="h4">Videos</Typography>
        </Grid>
      </Grid>
      <Grid
        spacing={1}
        container
        flexWrap={"wrap"}
        justifyContent={"space-between"}
        px={2}
        pb={2}
      >
        <Grid item flex={1.2}>
          <Autocomplete
            id="combo-box-demo"
            options={event}
            getOptionLabel={(option) => {
              return option || "";
            }}
            multiple
            value={eventTypes}
            onChange={handleEventTypeChange}
            isOptionEqualToValue={(option, value) => {
              return option === value;
            }}
            noOptionsText="No event found"
            onInputChange={(event: any, newValue: any) => {
              setEvents(events.filter((item) => item.includes(newValue)));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Event"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {productLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  )
                }}
              />
            )}
          />
        </Grid>

        <Grid item flex={1}>
          <Autocomplete
            multiple
            id="combo-box-demo"
            options={filteredLocationData}
            getOptionLabel={(option) => {
              return option?.location ? option?.location : "";
            }}
            value={locationName}
            onChange={(event: any, newValue: any) => {
              localStorage.setItem("location", JSON.stringify(newValue));
              setLocationName(newValue);
            }}
            isOptionEqualToValue={(option, value) => {
              return option?.location === value?.location;
            }}
            noOptionsText="No Location found"
            onInputChange={(event: any, newValue: any) => {
              fetchLocationList({input: newValue});
            }}
            loading={cameraLoading}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Camera Location"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {productLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  )
                }}
              />
            )}
          />
        </Grid>

        <Grid item flex={1}>
          <Autocomplete
            multiple
            id="combo-box-demo"
            options={dogList}
            getOptionLabel={(option) => {
              return option?.name ? option?.name : "";
            }}
            isOptionEqualToValue={(option, value) => {
              return option?._id === value?._id;
            }}
            value={dogName}
            onChange={(event: any, newValue: any) => {
              localStorage.setItem("dogsSelected", JSON.stringify(newValue));
              setDogName(newValue);
            }}
            noOptionsText="No dog found"
            onInputChange={(event: any, newValue: any) => {
              fetchDogList({input: newValue});
            }}
            loading={productLoading}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Dog"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {productLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  )
                }}
              />
            )}
          />
        </Grid>

        <Grid item flex={1}>
          <TextField
            fullWidth
            focused={false}
            onClick={(e) => {
              setDateRangeModal(true);
            }}
            autoComplete="off"
            autoCorrect="off"
            autoSave="false"
            label="Date Range"
            style={{color: "#223354"}}
            value={
              dateRange[0] && dateRange[1]
                ? `${moment(dateRange[0]).format("DD/MM/YYYY")} - ${moment(
                    dateRange[1]
                  ).format("DD/MM/YYYY")}`
                : ""
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarMonthIcon />
                </InputAdornment>
              )
            }}
          />

          {dateRangeModal && (
            <DatePickerModal
              handleDateRangeChange={handleDateRangeChange}
              open={dateRangeModal}
              initialSelectionRange={dateRange}
              handleClose={handleClearRange}
            />
          )}
        </Grid>
      </Grid>

      {!initialLoading && products?.length === 0 && (
        <Card
          variant="outlined"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 20,
            height: "60vh"
          }}
        >
          <CardContent>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
            >
              <img src={dog} alt="dog" />

              <Typography variant="h6" className="my-2">
                No videos found, please try another time
              </Typography>
            </Box>
          </CardContent>
        </Card>
      )}

      {initialLoading && (
        <Card
          variant="elevation"
          style={{
            position: "absolute",
            margin: 20,
            top: 180,
            left: 0,
            right: 0,
            bottom: 0,
            height: "80vh",
            backgroundColor: "transparent"
          }}
        >
          <Box
            style={{
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.2)"
            }}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
          >
            <Box display={"flex"} mt={1}>
              <CircularProgress style={{marginRight: 20}} />
            </Box>
          </Box>
        </Card>
      )}

      <Grid
        container
        spacing={2}
        onScroll={onScroll}
        ref={listInnerRef}
        style={{
          padding: "16px",
          height: products?.length ? "80vh" : "auto",
          overflow: "scroll"
        }}
      >
        {products?.map((product, index) => (
          <Grid item xs={12} key={index}>
            <Card variant="outlined">
              <CardContent>
                {getSectionTitle(index)}

                <Divider style={{margin: "10px 0 20px 0"}} />

                <Grid container spacing={2}>
                  {product?.data.map((dProducts: any, key) => (
                    <Grid item xs={12} sm={6} md={3} key={key}>
                      <PreviewVideo
                        {...dProducts}
                        onClick={() => handleClick(dProducts?._id)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}

        {loading && (
          <Box py={2} margin="auto">
            <CircularProgress />
          </Box>
        )}
      </Grid>
    </>
  );
}

const MemoVideos = React.memo(Videos, () => true);

export default MemoVideos;
