// web-react-admin-lavabee\src\modules\videos\EditVideo\components\VideoMetadata.tsx
import React from 'react';
import { Box, Typography } from "@mui/material";
interface MetadataItemProps {
  label: string;
  value: string;
}

interface VideoMetadataProps {
  metadata: {
    dogName: string;
    location: string;
    rfid: string;
    utcTime: string;
    dallasTime: string;
    event: string;
  } | null;
}

const VideoMetadata: React.FC<VideoMetadataProps> = ({ metadata }) => (
  <Box flex={0}>
    {metadata && (
      <>
        <MetadataItem label="DOG Name:" value={metadata.dogName} />
        <MetadataItem label="DOG Location:" value={metadata.location} />
        <MetadataItem label="RFID:" value={metadata.rfid} />
        <MetadataItem label="UTC:" value={metadata.utcTime} />
        <MetadataItem label="Dallas:" value={metadata.dallasTime} />
        <MetadataItem label="Event:" value={metadata.event} />
      </>
    )}
  </Box>
);

const MetadataItem: React.FC<MetadataItemProps> = ({ label, value }) => (
  <Box display="flex">
    <Typography variant="h6" component="h6" fontSize={14}>
      {label}
    </Typography>
    <Typography variant="subtitle1" component="p" ml={0.5}>
      {value}
    </Typography>
  </Box>
);

export default VideoMetadata;