import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Box,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";

import HlsVideoPlayer from "../HlsVideoPlayer";

import CancelIcon from "@mui/icons-material/Cancel";
import DownloadIcon from "@mui/icons-material/Download";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { optimizeVideoURL } from "../../utilities/helpers";

import { styled } from "@mui/material/styles";

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: 20,
  padding: "10px 24px",
  textTransform: "none",
  fontWeight: "bold",
  letterSpacing: "0.06em",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  transition: "all 0.3s ease",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0 6px 8px rgba(0, 0, 0, 0.15)",
  },
  "&.MuiButton-outlined": {
    borderWidth: 2,
    "&:hover": {
      borderWidth: 2,
    },
  },
}));

interface Props {
  media: string;
  open: boolean;
  handleClose: () => void;
  isVideo?: boolean;
  planColor?: string;
}

const ViewMediaDialog = ({
  media,
  open,
  handleClose,
  isVideo = false,
  planColor,
}: Props) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const getFileNameAndFormat = (url: string) => {
    const urlWithoutParams = url.split("?")[0];

    const urlParts = urlWithoutParams.split("/");
    const fileName = urlParts[urlParts.length - 1];
    const lastDotIndex = fileName.lastIndexOf(".");

    if (lastDotIndex !== -1) {
      const nameWithoutExtension = fileName.slice(0, lastDotIndex);
      const fileExtension = fileName.slice(lastDotIndex + 1);
      return `${nameWithoutExtension}.${fileExtension}`;
    } else {
      return fileName;
    }
  };

  const downloadFile = (fileUrl: string, fileName: string) => {
    fetch(fileUrl, { method: "GET", cache: "no-cache" })
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");

        link.href = url;
        link.download = fileName;
        link.style.display = "none";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        URL.revokeObjectURL(url);

        setIsDownloading(false);
      })
      .catch((error) => {
        setIsDownloading(false);
        console.error("File download failed:", error);
      });
  };

  const handleViewMediaInBrowser = (url: string) => {
    window.open(url, "_blank");
  };

  const RenderMedia = () => {
    if (isVideo) {
      return <HlsVideoPlayer src={optimizeVideoURL(media)} />;
    } else {
      return (
        <img
          src={media}
          style={{
            maxWidth: "650px",
            maxHeight: "100%",
            objectFit: "contain",
            width: "100%",
            height: "auto",
          }}
        />
      );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          maxWidth: "100%",
        },
      }}
    >
      <DialogTitle id="dialog-title">
        <Box
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>
            {media
              ? getFileNameAndFormat(media)
              : isVideo
              ? "Full Video"
              : "Full Image"}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ maxHeight: "calc(100vh - 160px)" }}>
        <Stack gap={2}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "auto",
              maxHeight: "60vh",
              padding: 4,
              borderRadius: 2,
              maxWidth: "100%",
              bgcolor: planColor ?? "#fff",
            }}
          >
            <RenderMedia />
          </Box>
          <Stack justifyContent="space-between" direction="row" spacing={3}>
            <StyledButton
              variant="outlined"
              color="error"
              size="large"
              onClick={handleClose}
              startIcon={<CancelIcon />}
            >
              Close
            </StyledButton>
            <Stack direction="row" spacing={2}>
              <StyledButton
                variant="outlined"
                size="large"
                onClick={() => handleViewMediaInBrowser(media)}
                startIcon={<OpenInNewIcon />}
              >
                View Original
              </StyledButton>
              <StyledButton
                variant="contained"
                size="large"
                disabled={isDownloading}
                onClick={() => {
                  setIsDownloading(true);
                  downloadFile(media, getFileNameAndFormat(media));
                }}
                startIcon={
                  isDownloading ? (
                    <CircularProgress size={16} color="inherit" />
                  ) : (
                    <DownloadIcon />
                  )
                }
              >
                {isDownloading ? "Downloading..." : "Download"}
              </StyledButton>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ViewMediaDialog;
