import {useState, useEffect, useRef} from "react";
import {useMutation} from "@apollo/client";

import {UPDATE_PRODUCT} from "../../shared/graphQL/dog/queries";
import {GENERATE_PRESIGNED_URL} from "../../shared/graphQL/common/queries";

import {Grid, Paper, Typography} from "@mui/material";

import DogImage from "./DogImage";

export enum DogGalleryType {
  GALLERY = "gallery",
  CRESTS = "crests"
}

interface DogGalleryProps {
  title: string;
  size: number;
  limit: number;
  type: string;
  data: any;
  dogId: string;
  refetch: () => {};
}

const DogGallery = ({
  title,
  size,
  limit,
  type,
  data,
  dogId,
  refetch
}: DogGalleryProps) => {
  const [actualIndex, setActualIndex] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [tempDogAlbum, setTempDogAlbum] = useState([]);
  const [dogAlbum, setDogAlbum] = useState(data || []);
  const [uploadProgress, setUploadProgress] = useState([]);
  const [uploadText, setUploadText] = useState(null);
  const [totalProgress, setTotalProgress] = useState(0);
  const abortControllerRef = useRef(null);

  const [generatePresignedUrl] = useMutation(GENERATE_PRESIGNED_URL);
  const [updateProduct, {data: updateProductData}] =
    useMutation(UPDATE_PRODUCT);

  const wdfs3URL = process.env.WDFS3URL;
  const wdfs3oldURL = process.env.WDFOLDS3URL;

  useEffect(() => {
    if (updateProductData) {
      setIsUploading(false);
      setIsDeleting(false);
      setIsEditing(false);
      setActualIndex(null);
      setDogAlbum(tempDogAlbum);
      refetch();
    }
  }, [updateProductData]);

  useEffect(() => {
    if (uploadProgress.length > 0) {
      const totalProgress = uploadProgress.reduce((acc, curr, index) => {
        const weight = index === 0 ? 0.8 : 0.2;
        const progress = typeof curr === "number" ? curr : 0;
        return acc + progress * weight;
      }, 0);
      setTotalProgress(totalProgress);
    }
  }, [uploadProgress]);

  const updateAlbum = (dogAlbum: any[]) => {
    const allImages = dogAlbum.map((item) => JSON.stringify(item)).join(", ");
    let payload;

    if (type === DogGalleryType.GALLERY) {
      payload = {
        dogAlbum: `[${allImages}]`
      };
    } else {
      payload = {
        dogCrests: `[${allImages}]`
      };
    }

    updateProduct({
      variables: {id: {id: dogId}, input: payload}
    });
    setTempDogAlbum(dogAlbum);
  };

  const handleDeleteImage = (indexToRemove: number) => {
    setIsDeleting(true);
    const newArray = dogAlbum.filter(
      (e: any, index: number) => index !== indexToRemove
    );

    updateAlbum(newArray);
  };

  const handleEditImage = (newMediaItem: any, indexToEdit: number) => {
    const newArray = dogAlbum.filter(
      (e: any, index: number) => index !== indexToEdit
    );

    newArray.splice(indexToEdit, 0, newMediaItem);

    updateAlbum(newArray);
  };

  const handleNewImage = (newMediaItem: any) => {
    const newArray = [...dogAlbum, newMediaItem];

    updateAlbum(newArray);
  };

  const generateThumbnailFileName = (fileName: string): string => {
    const parsedFileName = fileName.replace(/\.[^/.]+$/, "");
    const sanitizedFileName = parsedFileName.replace(/[^a-zA-Z0-9]/g, "_");
    return `${sanitizedFileName}.jpg`;
  };

  const uploadFileWithProgress = async (
    url,
    file,
    setProgress,
    partIndex,
    abortSignal
  ) => {
    return new Promise<number>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", url, true);
      xhr.setRequestHeader("Content-Type", file.type);

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded / event.total) * 100);
          setProgress((prevProgress) => {
            const newProgress = [...prevProgress];
            newProgress[partIndex] = progress;
            return newProgress;
          });
        }
      };

      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(100);
        }
      };

      xhr.send(file);

      abortSignal.addEventListener("abort", () => {
        xhr.abort();
      });
    });
  };

  const handleAbortFileUpload = () => {
    abortControllerRef.current.abort();
    setIsUploading(false);
    setTotalProgress(0);
    setUploadProgress([]);
  };

  const handleFileChange = async (event, type, index) => {
    event.preventDefault();
    setUploadText("Loading file...");

    if (type === "edit") {
      setActualIndex(index);
      setIsEditing(true);
    } else {
      setIsUploading(true);
    }

    const file = event.target.files[0];
    const isVideo = file.type.startsWith("video/");
    const totalParts = isVideo ? 2 : 1;

    let payload = {
      fileName: file?.name,
      fileType: file.type,
      filePath: "sponsor"
    };

    const response = await generatePresignedUrl({
      variables: {input: payload}
    });

    const fileURL = response.data.GeneratePresignedUrl.presignedUrl;

    setUploadProgress(Array(totalParts).fill(0));
    abortControllerRef.current = new AbortController();

    try {
      setUploadText(`Uploading ${isVideo ? "video" : "image"}...`);
      await uploadFileWithProgress(
        fileURL,
        file,
        setUploadProgress,
        0,
        abortControllerRef.current.signal
      );

      const finalURL = fileURL.split("?")[0]
        ? fileURL.split("?")[0]?.replace(wdfs3oldURL, wdfs3URL)
        : "";

      if (isVideo) {
        setUploadText("Generating thumbnail...");

        const video = document.createElement("video");
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        video.src = URL.createObjectURL(file);
        await new Promise((resolve) => {
          video.onloadedmetadata = resolve;
        });

        video.currentTime = 1.5;

        await new Promise((resolve) => {
          video.onseeked = resolve;
        });

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

        canvas.toBlob(async (blob) => {
          const thumbnailFileName = generateThumbnailFileName(file.name);
          const thumbnailFile = new File([blob], thumbnailFileName, {
            type: "image/jpeg"
          });

          const thumbnailPayload = {
            fileName: thumbnailFileName,
            fileType: thumbnailFile.type,
            filePath: "sponsor"
          };

          const thumbnailResponse = await generatePresignedUrl({
            variables: {input: thumbnailPayload}
          });

          const thumbnailURL =
            thumbnailResponse.data.GeneratePresignedUrl.presignedUrl;

          setUploadText("Uploading thumbnail...");
          await uploadFileWithProgress(
            thumbnailURL,
            thumbnailFile,
            setUploadProgress,
            1,
            abortControllerRef.current.signal
          );

          const finalThumbnailURL = thumbnailURL.split("?")[0]
            ? thumbnailURL.split("?")[0]?.replace(wdfs3oldURL, wdfs3URL)
            : "";

          const mediaItem = {
            type: "video",
            url: finalURL,
            thumbnail: finalThumbnailURL
          };

          setIsUploading(false);
          setTotalProgress(0);
          setUploadProgress([]);

          if (type === "edit") {
            handleEditImage(mediaItem, index);
          } else {
            handleNewImage(mediaItem);
          }
        }, "image/jpeg");
      } else {
        const mediaItem = {
          type: "image",
          url: finalURL
        };

        setIsUploading(false);
        setTotalProgress(0);
        setUploadProgress([]);

        if (type === "edit") {
          handleEditImage(mediaItem, index);
        } else {
          handleNewImage(mediaItem);
        }
      }
    } catch (error) {
      if (error.message === "Upload cancelled") {
        // console.log("Upload was cancelled");
      } else {
        // console.error("Upload failed", error);
      }

      setIsUploading(false);
      setTotalProgress(0);
      setUploadProgress([]);
    }
  };

  return (
    <>
      <Typography variant="h6" sx={{fontWeight: "bold", my: 1, fontSize: 16}}>
        {title}
      </Typography>
      <Paper elevation={3} sx={{padding: 1.5, mt: 2, mb: 2}}>
        <Grid container spacing={2} marginBottom={2}>
          {dogAlbum.slice(0, limit).map((media: any, index: number) => (
            <DogImage
              media={media}
              type={type}
              actualIndex={actualIndex}
              setActualIndex={setActualIndex}
              handleDeleteImage={handleDeleteImage}
              handleFileChange={handleFileChange}
              isDeleting={isDeleting}
              isEditing={isEditing}
              isUploading={isUploading}
              size={size}
              key={type + index}
              index={index++}
            />
          ))}
          {dogAlbum.length < limit && (
            <DogImage
              media={null}
              type={type}
              handleFileChange={handleFileChange}
              isUploading={isUploading}
              uploadProgress={totalProgress}
              uploadText={uploadText}
              handleAbortFileUpload={handleAbortFileUpload}
              size={size}
              key={type + "upload"}
              index={0}
            />
          )}
        </Grid>
      </Paper>
    </>
  );
};

export default DogGallery;
