// web-react-admin-lavabee\src\modules\videos\EditVideo\components\ActionButtons.tsx
import React from 'react';
import { Stack, Button, LinearProgress, Box, Typography, useTheme, ButtonGroup, Tooltip, CircularProgress } from "@mui/material";
import { Save, Publish, GetApp, Delete, Cancel } from "@mui/icons-material";

interface ActionButtonsProps {
  buttonsDisabled: boolean;
  handlePost: () => void;
  handleDownload: () => void;
  handleDelete: () => void;
  handleSave: () => Promise<void>;
  cancelOperation: () => void;
  postStatus: boolean;
  downloadStatus: boolean;
  processProgress: number;
  isSaving: boolean;
  setSaveEditSuccess: (value: boolean) => void;
  setSaveEditError: (value: boolean) => void;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
  buttonsDisabled,
  handlePost,
  handleDownload,
  handleDelete,
  handleSave,
  cancelOperation,
  postStatus,
  downloadStatus,
  processProgress,
  isSaving,
  setSaveEditSuccess,
  setSaveEditError
}) => {
  const handleSaveClick = async () => {
    try {
      await handleSave();
      setSaveEditSuccess(true);
    } catch (error) {
      setSaveEditError(true);
    }
  };

  return (
    <Stack
      direction="column"
      spacing={3}
      mt={4}
    >
      <Box sx={{ height: 40, mb: 2 }}>
        {(postStatus || downloadStatus) && (
          <Box sx={{ position: 'relative', width: '100%' }}>
            <LinearProgress
              variant="determinate"
              value={processProgress > 100 ? 0 : processProgress}
              sx={{
                height: 8,
                borderRadius: 4,
                '& .MuiLinearProgress-bar': {
                  borderRadius: 4,
                }
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                top: '100%',
                left: 0,
                right: 0,
                textAlign: 'center',
                mt: 1,
              }}
            >
              <Typography variant="body2" color="textSecondary">
                {`Processing video ${processProgress > 100 ? 0 : processProgress}%`}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      <ButtonGroup
        variant="outlined"
        fullWidth
        sx={(theme) => ({
          borderRadius: 2,
          '& .MuiButton-root': {
            textTransform: 'none',
            fontSize: '14px',
            fontWeight: 'bold',
            padding: '10px 16px',
            transition: 'all 0.2s ease',
            letterSpacing: '0.05em',
            '&:hover': {
              transform: 'translateY(-2px)',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              borderColor: 'currentColor',
            },
            borderColor: (postStatus || downloadStatus) ? 'transparent' : 'currentColor',
          },
          borderColor: (postStatus || downloadStatus) ? 'transparent' : 'currentColor',
        })}
      >
        <Tooltip title="Save edit changes to the video" arrow placement="bottom">
          <Button
            onClick={handleSaveClick}
            disabled={buttonsDisabled || isSaving}
            startIcon={isSaving ? <CircularProgress size={20} /> : <Save />}
            sx={{
              color: '#339337',
              borderColor: '#339337',
              '&:hover': {
                backgroundColor: 'rgba(76, 175, 80, 0.04)',
                borderColor: '#339337',
              },
            }}
          >
            {isSaving ? 'Saving...' : 'Save edits'}
          </Button>
        </Tooltip>
        <Tooltip title={postStatus ? "Cancel posting" : "Post the edited video"} arrow placement="bottom">
          <Button
            onClick={postStatus ? cancelOperation : handlePost}
            disabled={buttonsDisabled && !postStatus}
            startIcon={postStatus ? <Cancel /> : <Publish />}
            color="primary"
          >
            {postStatus ? "Cancel" : "Post"}
          </Button>
        </Tooltip>
        <Tooltip title={downloadStatus ? "Cancel download" : "Download the edited video"} arrow placement="bottom">
          <Button
            onClick={downloadStatus ? cancelOperation : handleDownload}
            disabled={buttonsDisabled && !downloadStatus}
            startIcon={downloadStatus ? <Cancel /> : <GetApp />}
            color="primary"
          >
            {downloadStatus ? "Cancel" : "Download"}
          </Button>
        </Tooltip>
        <Tooltip title="Delete this video" arrow placement="bottom">
          <Button
            onClick={handleDelete}
            disabled={buttonsDisabled}
            startIcon={<Delete />}
            color="error"
          >
            Delete
          </Button>
        </Tooltip>
      </ButtonGroup>
    </Stack>
  );
};

export default ActionButtons;