import { useCallback, useEffect, useState, useRef } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router";
import { useForm } from "react-hook-form";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
  useTheme
} from "@mui/material";

import {
  GET_PLAN,
  GET_PLANS,
  ASSIGN_DOGS,
  DELETE_PLAN
} from "../../shared/graphQL/subscription/queries";
import { GET_PRODUCTS } from "../../shared/graphQL/dog/queries";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import DeleteIcon from "@mui/icons-material/Delete";

import ViewMediaDialog from "../../shared/components/VideoMediaDialog";
import DogCatalog from "./DogCatalog";
import DialogComponent from "../../shared/components/Dialog";
import SubmitEditPlanDialog from "./SubmitEditPlanDialog";

enum DogCatalogType {
  ALL = 0,
  MY_DOGS = 1
}

const PlanDetails = () => {
  const theme = useTheme();
  const { id } = useParams();
  const [planData, setPlanData] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [error, setError] = useState(null);
  const [openError, setOpenError] = useState(false);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [clickedImage, setClickedImage] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [myDogs, setMyDogs] = useState([]);
  const [allDogs, setAllDogs] = useState([]);
  const [isMyDogsModified, setIsMyDogsModified] = useState(false);
  const [filters, setFilters] = useState({
    dogStatus: null
  });
  const [selectedData, setSelectedData] = useState(null);

  const allDogsRef = useRef(allDogs);
  const myDogsRef = useRef(myDogs);
  const profileDataRef = useRef(profileData);

  const [
    getPlans,
    { data: getAllPlans, loading: planLoader, refetch: refetchPlans }
  ] = useLazyQuery(GET_PLANS);
  const [getPlan, { data: getPlanData, refetch }] = useLazyQuery(GET_PLAN);
  const [getProducts, { data: getAllProducts }] = useLazyQuery(GET_PRODUCTS, {
    fetchPolicy: "no-cache"
  });
  const [
    assignProducts,
    { data: assignData, loading: assignLoader, error: assignError }
  ] = useMutation(ASSIGN_DOGS, { onError: (error) => { } });
  const [deletePlan, { data: deletePlanData }] = useMutation(DELETE_PLAN);

  const {
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      descriptions: [{ description: "" }],
      planImage: "",
      planColor: "",
      nftImage: "",
      price: "",
      name: "",
      supportableProductCount: "",
      mtdescription: ""
    }
  });

  useEffect(() => {
    getPlan({
      variables: {
        input: {
          planId: id
        }
      }
    });
  }, [id]);

  useEffect(() => {
    getPlans({
      variables: {
        input: {
          pageDto: {
            page: 1,
            limit: 10,
            order_by: null
          },
          search: ""
        }
      }
    });
  }, []);

  useEffect(() => {
    allDogsRef.current = allDogs;
    myDogsRef.current = myDogs;
    profileDataRef.current = profileData;

    if (getPlanData && getAllProducts) {
      const assignedDogs = getPlanData.GetPlan?.products || [];
      const myDogs = myDogsRef.current.map((dog) => dog._id);

      const myDogsModified = arraysAreEqual(myDogs, assignedDogs);

      setIsMyDogsModified(myDogsModified);
    }
  }, [allDogs, myDogs, profileData]);

  useEffect(() => {
    if (getPlanData) {
      const data = getPlanData.GetPlan;
      setPlanData(data);

      const { default_price } = data;
      const { price, recurring, renewal_period, supportable_product_count } =
        default_price;

      const profileData = {
        price: `$${price}`,
        recurring: recurring ? "Yes" : "No",
        renewal_period: renewal_period === "month" ? "Monthly" : "Annual",
        supportable_product_count: parseInt(supportable_product_count) || "All"
      };

      setProfileData(profileData);
    }
  }, [getPlanData]);

  useEffect(() => {
    if (assignData && !assignError) {
      setOpen(true);
      setIsMyDogsModified(true);
      refetch();
    } else {
      //
    }
  }, [assignData, assignError]);

  useEffect(() => {
    getProducts({
      variables: {
        input: {
          pageDto: {
            page: 1,
            limit: 128,
            order_by: null
          },
          search: "",
          status: filters.dogStatus?.value
        }
      }
    });
  }, [filters]);

  useEffect(() => {
    if (getAllProducts && getPlanData) {
      const assignedDogs = getPlanData.GetPlan?.products || [];

      const products = JSON.parse(
        getAllProducts.retrieveProductsList.productsDataList
      );

      const myDogs = products.filter((dog) => assignedDogs.includes(dog._id));
      const allDogs = products.filter((dog) => !assignedDogs.includes(dog._id));
      const myDogsStrings = myDogs.map((dog) => dog._id);

      const myDogsModified = arraysAreEqual(myDogsStrings, assignedDogs);

      setMyDogs(myDogs);
      setAllDogs(allDogs);
      setIsMyDogsModified(myDogsModified);
    }
  }, [getAllProducts, getPlanData]);

  useEffect(() => {
    if (deletePlanData) {
      setIsDeleting(true);
      setOpenDelete(false);
      refetch();
    }
  }, [deletePlanData]);

  const addDogLogic = (selectedDogId, allDogs, myDogs, profileData) => {
    const selectedDog = allDogs.find((dog) => dog._id === selectedDogId);
    if (!selectedDog) {
      setError("Dog not found");
      setOpenError(true);
      return;
    }

    const allDogsWithoutSelected = allDogs.filter(
      (dog) => dog._id !== selectedDogId
    );
    const newMyDogs = [...myDogs, selectedDog];

    if (newMyDogs.length > profileData.supportable_product_count) {
      setError("Maximum dog limit reached");
      setOpenError(true);
      return;
    }

    setMyDogs(newMyDogs);
    setAllDogs(allDogsWithoutSelected);
  };

  const removeDogLogic = (selectedDogId, allDogs, myDogs) => {
    const selectedDog = myDogs.find((dog) => dog._id === selectedDogId);
    if (!selectedDog) {
      setError("Dog not found");
      setOpenError(true);
      return;
    }

    const myDogsWithoutSelected = myDogs.filter(
      (dog) => dog._id !== selectedDogId
    );
    const newAllDogs = [...allDogs, selectedDog];

    setMyDogs(myDogsWithoutSelected);
    setAllDogs(newAllDogs);
  };

  const saveDogsLogic = (myDogs: any[]) => {
    const parsedMyDogs = JSON.stringify(myDogs.map((dog) => dog._id));

    let updatePayload = {
      planId: id,
      productsIds: parsedMyDogs
    };

    assignProducts({ variables: { input: updatePayload } });
  };

  const handleAddDog = useCallback((selectedDogId) => {
    addDogLogic(
      selectedDogId,
      allDogsRef.current,
      myDogsRef.current,
      profileDataRef.current
    );
  }, []);

  const handleRemoveDog = useCallback((selectedDogId) => {
    removeDogLogic(selectedDogId, allDogsRef.current, myDogsRef.current);
  }, []);

  const handleSaveMyDogs = useCallback(() => {
    saveDogsLogic(myDogsRef.current);
  }, []);

  const handleOpenViewImage = (imageUrl) => {
    setOpenImage(true);
    setClickedImage(imageUrl);
  };

  const handleCloseViewImage = () => {
    setOpenImage(false);
    setClickedImage(null);
  };

  const handleCloseError = () => {
    setOpenError(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    deletePlan({ variables: { id: { id: deleteId } } });
    setOpenDelete(false);
  };

  const handleDeleteClick = (row) => {
    setDeleteId(row._id);
    setOpenDelete(true);
  };

  const handleEditClick = (row) => {
    let payload = {
      planId: row._id,
      plans: getAllPlans.GetPlans.plans,
      nft_media_url: row.nft_media_url
    };
    setIsEditing(true);
    setSelectedData(payload);
    setOpenEdit(true);
  };

  const handleCloseEditAndDelete = () => {
    setOpenEdit(false);
    setSelectedData(null);
    setOpenDelete(false);

    reset({ descriptions: [{ description: "" }] });
  };

  const formatDate = (dateToFormat) => {
    if (!dateToFormat) return "N/A";

    const date = new Date(dateToFormat);

    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const arraysAreEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    const set1 = new Set(arr1);
    const set2 = new Set(arr2);

    return set1.size === set2.size && [...set1].every((item) => set2.has(item));
  };

  const profileNames = {
    price: "Price",
    recurring: "Recurring",
    renewal_period: "Renewal Period",
    supportable_product_count: "Product Count"
  };

  return (
    <>
      <Box sx={{ m: 2 }}>
        <Box>
          {planData && (
            <>
              <Box display="flex" justifyContent={"space-between"}>
                <Typography
                  variant="h6"
                  style={{ fontSize: 30, fontWeight: 700 }}
                >
                  Details of {planData.name}
                </Typography>
                <Stack direction="row" style={{ marginLeft: "auto" }}>
                  <IconButton
                    sx={{
                      display: "flex",
                      gap: 0.5,
                      "&:hover": {
                        background: theme.colors.primary.lighter
                      },
                      color: theme.palette.primary.main
                    }}
                    color="inherit"
                    size="small"
                    onClick={() => handleEditClick(planData)}
                  >
                    <Typography variant="body1" sx={{ fontSize: 14, fontWeight: 700 }}>
                      Edit
                    </Typography>
                    <EditTwoToneIcon fontSize="small" sx={{ color: "#0481D9" }} />
                  </IconButton>

                  <IconButton
                    sx={{
                      "&:hover": { background: theme.colors.error.lighter },
                      color: theme.palette.error.main
                    }}
                    color="inherit"
                    size="small"
                    onClick={() => handleDeleteClick(planData)}
                  >
                    <DeleteTwoToneIcon fontSize="small" />
                  </IconButton>
                </Stack>
              </Box>
              <Paper elevation={3} sx={{ padding: 2 }}>
                <Grid container spacing={2} gap={3}>
                  <Grid item xs={2} display="flex" flexDirection="column" alignItems="center">
                    <Box
                      flexGrow={1}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      sx={{
                        pe: 2,
                        width: '100%',
                      }}
                    >
                      <Stack
                        onClick={() => handleOpenViewImage(planData.plan_image)}
                        sx={{
                          cursor: "pointer",
                          position: "relative",
                          marginBottom: "16px",
                          height: 164,
                          width: 164,
                          borderRadius: 2,
                          padding: 0.5,
                          backgroundColor: planData.plan_color,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          transition: "all 0.2s ease-in-out",
                          "&:hover": {
                            transform: "scale(1.03)",
                            boxShadow: (theme) => theme.shadows[10]
                          }
                        }}
                      >
                        <img
                          src={planData.plan_image}
                          alt="Sponsor"
                          style={{
                            width: "calc(100% - 16px)",
                            height: "calc(100% - 16px)",
                            borderRadius: '50%',
                            objectFit: 'cover'
                          }}
                        />
                      </Stack>
                      <Typography
                        variant="h6"
                        style={{
                          fontSize: 16,
                          fontWeight: 700,
                          textAlign: "center",
                          width: '100%'
                        }}
                      >
                        {planData?.name || ""}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={9} sx={{ display: "flex", mt: 2 }}>
                    <Grid container spacing={2} gap={2}>
                      <Grid item xs={12} md={5}>
                        {Object.keys(profileData).map((key, index) => (
                          <Box sx={{ display: "flex", my: 1 }} key={index}>
                            <Typography
                              variant="body1"
                              sx={{
                                fontWeight: 700,
                                fontSize: 16,
                                minWidth: 150
                              }}
                            >
                              {profileNames[key]}
                              {":"}
                            </Typography>
                            <Typography variant="body1">
                              {profileData[key]}
                            </Typography>
                          </Box>
                        ))}
                        <Box sx={{ display: "flex", my: 1 }}>
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: 700,
                              fontSize: 16,
                              minWidth: 150
                            }}
                          >
                            Created At:
                          </Typography>
                          <Typography variant="body1">
                            {formatDate(planData.createdAt)}
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex", my: 1, alignItems: "center" }}>
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: 700,
                              fontSize: 16,
                              minWidth: 150
                            }}
                          >
                            Plan Color:
                          </Typography>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            {planData.plan_color ? (
                              <>
                                <Box
                                  sx={{
                                    width: 48,
                                    height: 24,
                                    borderRadius: 0.5,
                                    backgroundColor: planData.plan_color,
                                    border: `1px solid ${theme.palette.divider}`,
                                    mr: 1
                                  }}
                                />
                                <Typography variant="body1">
                                  {planData.plan_color}
                                </Typography>
                              </>
                            ) : (
                              <Typography variant="body1">N/A</Typography>
                            )}
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
              <Paper elevation={3} sx={{ padding: 1.5, mt: 2 }}>
                <DogCatalog
                  title="Current Dogs in this Plan"
                  type={DogCatalogType.MY_DOGS}
                  data={myDogs}
                  isLoading={assignLoader}
                  isMyDogsModified={isMyDogsModified}
                  handleRemove={handleRemoveDog}
                  handleSave={handleSaveMyDogs}
                />

                <DogCatalog
                  title="All Dogs"
                  type={DogCatalogType.ALL}
                  data={allDogs}
                  isLoading={assignLoader}
                  handleAdd={handleAddDog}
                />
              </Paper>
            </>
          )}
        </Box>
      </Box>

      <ViewMediaDialog
        media={clickedImage}
        planColor={planData?.plan_color}
        open={openImage}
        handleClose={handleCloseViewImage}
      />

      <SubmitEditPlanDialog
        open={openEdit}
        handleClose={handleCloseEditAndDelete}
        isEditing={isEditing}
        selectedData={selectedData}
        refetch={refetchPlans}
      />

      <DialogComponent
        open={openError}
        width={324}
        height={240}
        handleClose={handleCloseError}
        content={
          <Box display="flex" flexDirection="column" alignItems="center">
            <ErrorOutlineIcon color="error" sx={{ fontSize: 72, mb: 4 }} />
            <DialogContentText
              id="alert-dialog-description"
              sx={{ color: "black" }}
            >
              <strong>{error}</strong>
            </DialogContentText>
          </Box>
        }
        actions={undefined}
      />

      <Dialog
        open={openDelete}
        onClose={handleCloseEditAndDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ width: 400, height: 300 }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <DeleteIcon
              sx={{ fontSize: 70, m: 2, color: "rgba(204, 43, 53, 1)" }}
            />
            <Typography sx={{ fontSize: "24px", fontWeight: 700 }}>
              Delete
            </Typography>
            <DialogContentText
              id="alert-dialog-description"
              sx={{ m: 2, fontWeight: 600 }}
            >
              Are you sure you want to delete this Plan?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCloseEditAndDelete}>
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ background: "rgba(204, 43, 53, 1)" }}
            onClick={handleDelete}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <DialogComponent
        open={open}
        width={324}
        height={240}
        handleClose={handleClose}
        content={
          <Box display="flex" flexDirection="column" alignItems="center">
            <CheckCircleIcon color="success" sx={{ fontSize: 72, mb: 4 }} />
            <DialogContentText
              id="alert-dialog-description"
              sx={{ color: "black" }}
            >
              <strong>Dogs successfully assigned</strong>
            </DialogContentText>
          </Box>
        }
        actions={undefined}
      />
    </>
  );
};

export default PlanDetails;
