// // web-react-admin-lavabee\src\modules\videos\VideoEditor\components\useTimelineDrag.ts
import { useState, useEffect, useCallback, RefObject } from "react";
import { runInAction } from "mobx";
import { mainStore } from "../main";

export const useVideoControls = (video: HTMLVideoElement, containerRef: RefObject<HTMLDivElement>) => {
  const [playing, setPlaying] = useState(!video?.paused);
  const [isMuted, setIsMuted] = useState(video?.muted);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      containerRef.current?.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  const togglePlayPause = () => {
    if (video?.paused) {
      video.play();
    } else {
      video.pause();
    }
    setPlaying(!video.paused);
  };

  const toggleMute = () => {
    runInAction(() => {
      const mute = !video.muted;
      video.muted = mute;
      mainStore.transform.mute = mute;
      setIsMuted(mute);
    });
  };

  const resetToOriginalPresets = useCallback(() => {
    if (mainStore.originalPresets) {
      runInAction(() => {
        mainStore.setRotation(mainStore.originalPresets.rotation || 0);
      });
      video.currentTime = mainStore.originalPresets.trimStart || 0;
      mainStore.transform.time = [
        mainStore.originalPresets.trimStart || 0,
        mainStore.originalPresets.trimEnd || video.duration
      ];
    } else {
      // Reset to default values if no original presets exist
      runInAction(() => {
        mainStore.setRotation(0);
      });
      video.currentTime = 0;
      mainStore.transform.time = [0, video.duration];
    }
    mainStore.reset(); // This will handle both cases in the MainStore
  }, [video]);

  useEffect(() => {
    resetToOriginalPresets();
  }, [resetToOriginalPresets]);
  
  useEffect(() => {
    const updatePlayingState = () => setPlaying(!video.paused);
    video.addEventListener("play", updatePlayingState);
    video.addEventListener("pause", updatePlayingState);
    return () => {
      video.removeEventListener("play", updatePlayingState);
      video.removeEventListener("pause", updatePlayingState);
    };
  }, [video]);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  return {
    playing,
    isMuted,
    isFullscreen,
    resetToOriginalPresets,
    toggleFullscreen,
    togglePlayPause,
    toggleMute
  };
};