import { useState, useEffect, Dispatch } from "react";
import { useForm } from "react-hook-form";
import { optimizeImageURL } from "../../shared/utilities/helpers";

import {
  Grid,
  Box,
  IconButton,
  Stack,
  useTheme,
  alpha,
  CircularProgress,
  Chip,
  LinearProgress,
  Button,
  Typography
} from "@mui/material";

import ViewMediaDialog from "../../shared/components/VideoMediaDialog";

import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import ImageIcon from "@mui/icons-material/Image";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import VideocamIcon from "@mui/icons-material/Videocam";

export enum DogGalleryType {
  GALLERY = "gallery",
  CRESTS = "crests",
}

interface Media {
  type: string;
  url: string;
  thumbnail?: string;
}

interface DogImageProps {
  media: Media;
  type: string;
  size: number;
  isEditing?: boolean;
  isDeleting?: boolean;
  isUploading: boolean;
  uploadProgress?: number;
  uploadText?: string;
  handleAbortFileUpload?: () => void,
  handleFileChange: (event: any, type: string, index?: number) => Promise<void>;
  setActualIndex?: Dispatch<any>;
  actualIndex?: number;
  handleDeleteImage?: (indexToRemove: number) => void;
  index: number;
}

const DogImage = ({
  media,
  type,
  size,
  isEditing,
  isDeleting,
  isUploading,
  uploadProgress,
  uploadText,
  handleAbortFileUpload,
  handleFileChange,
  setActualIndex,
  actualIndex,
  handleDeleteImage,
  index,
}: DogImageProps) => {
  const theme = useTheme();

  const {
    register,
    formState: { errors },
  } = useForm();

  const [isHovered, setIsHovered] = useState(false);
  const [isVideo, setIsVideo] = useState(true);
  const [open, setOpen] = useState(false);
  const [containerSize, setContainerSize] = useState(size || 256);

  const isLoading = isEditing || isDeleting || isUploading;

  useEffect(() => {
    const checkVideoUrl = async () => {
      if (media) {
        const isVideoResult = await validateVideoUrl(media?.url);
        setIsVideo(isVideoResult);
      }
    };

    checkVideoUrl();
  }, [media]);

  const handleOpenDialog = () => {
    if (isLoading) {
      return;
    }
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const verifIndex = (index: number): boolean => {
    return actualIndex === index;
  };

  const validateVideoUrl = (url: string): boolean => {
    const videoExtensions = [".mp4", ".webm", ".ogg"];
    const extension = url.substring(url.lastIndexOf(".")).toLowerCase();
    return videoExtensions.includes(extension);
  };

  const imageFormats = "image/png, image/jpeg, image/webp";
  const fileSupport =
    type === DogGalleryType.GALLERY
      ? imageFormats + ", video/mp4"
      : imageFormats;
  const iconSize = type === DogGalleryType.GALLERY ? 64 : 48;

  return (
    <Grid
      item
      key={type + index}
      m={1}
      style={{
        position: "relative",
        width: containerSize,
        height: containerSize,
      }}
    >
      <Stack
        width={containerSize}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {media ? (
          <Box
            onClick={handleOpenDialog}
            sx={{
              width: containerSize,
              height: containerSize,
              position: "relative",
              display: "inline-block",
              cursor: "pointer",
            }}
          >
            {isVideo ? (
              <Box
                sx={{
                  borderRadius: 12,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: containerSize,
                  height: containerSize,
                }}
              >
                {/* <VideoThumbnail
                  videoUrl={media?.url}
                  width={containerSize}
                  height={containerSize}
                  borderRadius={12}
                  cacheKey={media?.url}
                /> */}
                <img
                  src={media?.thumbnail || "https://i.imgur.com/cK12AID.png"}
                  style={{
                    borderRadius: 12,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: containerSize,
                    height: containerSize,

                    objectFit: "cover",
                  }}
                />
              </Box>
            ) : (
              <img
                src={optimizeImageURL(media?.url, 256)}
                style={{
                  borderRadius: 12,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: containerSize,
                  height: containerSize,

                  objectFit: "cover",
                }}
              />
            )}

            {type === DogGalleryType.GALLERY && (
              <>
                {isVideo ? (
                  <Chip
                    size="small"
                    color="error"
                    label={"Video"}
                    icon={<VideocamIcon />}
                    sx={{ position: "absolute", top: 8, left: 8, opacity: 0.8 }}
                  />
                ) : (
                  <Chip
                    size="small"
                    color="primary"
                    label={"Image"}
                    icon={<ImageIcon />}
                    sx={{ position: "absolute", top: 8, left: 8, opacity: 0.8 }}
                  />
                )}
              </>
            )}
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                opacity: isHovered ? 1 : 0,
                transition: "opacity 0.3s",
              }}
            >
              {type === DogGalleryType.GALLERY && (
                <>
                  {isVideo ? (
                    <PlayCircleOutlineIcon
                      sx={{
                        color: theme.palette.background.default,
                        width: iconSize + 16,
                        height: iconSize + 16,
                      }}
                    />
                  ) : (
                    <FullscreenIcon
                      sx={{
                        color: theme.palette.background.default,
                        width: iconSize + 16,
                        height: iconSize + 16,
                      }}
                    />
                  )}
                </>
              )}
            </Box>

            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: isLoading
                  ? alpha(theme.palette.background.default, 0.5)
                  : "transparent",
                opacity: isLoading ? 1 : 0,
                transition: "opacity 0.3s",
                zIndex: isLoading ? 1 : 0,
              }}
            >
              {isLoading && verifIndex(index) && (
                
                <CircularProgress size={64} sx={{ color: "#0481D9" }} />
              )}
            </Box>
            <Box
              sx={{
                position: "absolute",
                bottom: 7,
                right: 7,
                display: "flex",
                opacity: isHovered ? 1 : 0,
                transition: "opacity 0.3s",
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              {!isLoading && (
                <>
                  <IconButton
                    onClick={(e) => e.stopPropagation()}
                    component="label"
                    sx={{
                      background: alpha(theme.palette.primary.main, 0.5),
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: theme.palette.primary.main,
                      },
                    }}
                    color="inherit"
                    size="small"
                    htmlFor={`editImage-${type}`}
                  >
                    <EditTwoToneIcon fontSize="small" />
                    <input
                      id={`editImage-${type}`}
                      type="file"
                      accept="image/png, image/jpeg, image/webp"
                      {...register(type, {
                        onChange: (e) => handleFileChange(e, "edit", index),
                      })}
                      hidden
                    />
                  </IconButton>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setActualIndex(index);
                      handleDeleteImage(index);
                    }}
                    sx={{
                      marginLeft: 1,
                      background: alpha(theme.palette.error.main, 0.5),
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: theme.palette.error.main,
                      },
                    }}
                    color="inherit"
                    size="small"
                  >
                    <DeleteTwoToneIcon fontSize="small" />
                  </IconButton>
                </>
              )}
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              width: containerSize,
              height: containerSize,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "-0.2px 0.3px 1px 0.6px #cec9c9",
              borderRadius: "12px",
              position: "relative",
            }}
          >
            <svg
              width={containerSize}
              height={containerSize}
              viewBox={`0 0 ${containerSize} ${containerSize}`}
              fill={theme.palette.background.default}
              style={{ borderRadius: 12 }}
            >
              <rect width={containerSize} height={containerSize} />
            </svg>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {isLoading && !actualIndex ? (
                <Stack gap={2.5} direction="column" alignItems="center" width={containerSize}>
                  <CircularProgress size={52} sx={{ color: "#0481D9" }} />
                  <Stack gap={2} alignItems="center" width="100%">
                    <Stack alignItems="center" width="100%">
                      {uploadText && (
                        <Typography>{uploadText}</Typography>
                      )}
                      <LinearProgress variant="determinate" value={uploadProgress} sx={{ width: "80%" }} />
                    </Stack>
                    <Button onClick={handleAbortFileUpload} variant="outlined" color="error" size="small">Cancel</Button>
                  </Stack>
                </Stack>
              ) : (
                <IconButton
                  component="label"
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 0)",
                    "&:hover": {
                      background: theme.colors.primary.lighter,
                    },
                  }}
                  color="inherit"
                  htmlFor={`uploadImage-${type}`}
                  size="large"
                >
                  <AddPhotoAlternateIcon
                    fontSize="large"
                    sx={{ color: "#0481D9", width: iconSize, height: iconSize }}
                  />
                  <input
                    id={`uploadImage-${type}`}
                    type="file"
                    accept={fileSupport}
                    {...register(type, {
                      onChange: (e) => handleFileChange(e, "upload"),
                    })}
                    hidden
                  />
                </IconButton>
              )}
            </Box>
          </Box>
        )}
      </Stack>
      <ViewMediaDialog
        media={isVideo ? media?.url : optimizeImageURL(media?.url)}
        open={open}
        handleClose={handleCloseDialog}
        isVideo={isVideo}
      />
    </Grid>
  );
};

export default DogImage;
