// web-react-admin-lavabee\src\modules\Subscription\SubmitEditPlanDialog.tsx
import React, {useState, useEffect, useCallback, useRef, useMemo} from "react";
import {
  TextField,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Grid,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Popper,
  ClickAwayListener,
  Paper,
  Grow,
  useTheme,
  Tooltip
} from "@mui/material";
import {Controller, useFieldArray, useForm, useWatch} from "react-hook-form";
import CheckIcon from "@mui/icons-material/Check";
import {useMutation, useQuery} from "@apollo/client";
import {HexColorPicker} from "react-colorful";
import {
  CREATE_SUBSCRIPTION,
  UPDATE_PLAN,
  GET_PLAN
} from "../../shared/graphQL/subscription/queries";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import DeleteIcon from "@mui/icons-material/Delete";
import SuspenseLoader from "../../shared/components/SuspenseLoader";
import axios from "axios";

import IconImagePlaceholder from "../../assets/images/iconImagePlaceholder.svg";
import NFTImagePlaceholder from "../../assets/images/nftImagePlaceholder.svg";

import {
  StyledDialog,
  PreviewCard,
  FeatureItem,
  IconWrapper,
  PriceWrapper,
  PreviewHeader,
  ColorPickerWrapper
  // @ts-ignore // Need to review why is this throwing error
} from "./SubmitEditPlanDialog.styles";
import {Area} from "react-easy-crop";
import ImageUpload from "../../shared/components/ImageUpload";
import {GENERATE_PRESIGNED_URL} from "../../shared/graphQL/common/queries";

interface SubmitEditPlanDialogProps {
  open: boolean;
  handleClose: () => void;
  isEditing: boolean;
  selectedData: any;
  refetch: () => void;
}

interface UploadParams {
  file: File;
  filePath: string;
  fileName?: string;
}

export interface ImageData {
  file: File;
  croppedArea: Area | null;
  croppedAreaPixels: Area | null;
  preview: string;
}

const getFileExtension = (url: string): string => {
  const match = url.match(/\.([^.]+)$/);

  return match ? match[1].toLowerCase() : "jpg";
};

const createFileFromUrl = async (
  url: string,
  imageType: string
): Promise<File> => {
  const extension = getFileExtension(url);
  try {
    const response = await fetch(url, {
      method: "GET",
      cache: "no-cache"
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const blob = await response.blob();
    const fileName = `${imageType}_image.${extension}`;

    return new File([blob], fileName, {type: blob.type});
  } catch (error) {
    console.warn("Error fetching image:", error);
    console.warn("URL:", url);

    // Fallback: create an empty file
    return new File([], `${imageType}_image_error.txt`, {type: "text/plain"});
  }
};

const getCroppedImg = async (imageData: ImageData): Promise<Blob | null> => {
  const image = await createImage(imageData.preview);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const {croppedAreaPixels: pixelCrop, file} = imageData;

  if (!ctx) {
    return null;
  }

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob);
    }, `image/${file.type}`);
  });
};

const getCroppedImageFile = async (imageData: ImageData): Promise<File> => {
  const croppedImageBlob = await getCroppedImg(imageData);
  const croppedImageFile = new File([croppedImageBlob], imageData.file.name, {
    type: croppedImageBlob.type
  });

  return croppedImageFile;
};

const calculateInitialCroppedArea = (
  imageWidth: number,
  imageHeight: number,
  aspectRatio: number
): Area => {
  let cropWidth, cropHeight;
  if (imageWidth / imageHeight > aspectRatio) {
    cropHeight = 100;
    cropWidth = (cropHeight * aspectRatio * imageHeight) / imageWidth;
  } else {
    cropWidth = 100;
    cropHeight = ((cropWidth / aspectRatio) * imageWidth) / imageHeight;
  }

  const x = (100 - cropWidth) / 2;
  const y = (100 - cropHeight) / 2;

  return {
    x: Math.max(0, Math.min(100, x)),
    y: Math.max(0, Math.min(100, y)),
    width: Math.max(0, Math.min(100, cropWidth)),
    height: Math.max(0, Math.min(100, cropHeight))
  };
};

const createImageData = async (
  imageSrc: string | null,
  imageType: string,
  aspectRatio: number
): Promise<ImageData> => {
  if (!imageSrc) {
    return {
      file: new File([], "empty"),
      croppedArea: {x: 0, y: 0, width: 100, height: 100},
      croppedAreaPixels: null,
      preview: null
    };
  }

  const file = await createFileFromUrl(imageSrc, imageType);

  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      const croppedArea = calculateInitialCroppedArea(
        img.width,
        img.height,
        aspectRatio
      );
      resolve({
        file,
        croppedArea,
        croppedAreaPixels: null,
        preview: imageSrc
      });
    };
    img.src = imageSrc;
  });
};

const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });

const SubmitEditPlanDialog: React.FC<SubmitEditPlanDialogProps> = ({
  open,
  handleClose,
  isEditing,
  selectedData,
  refetch
}) => {
  const [isIpfsLoading, setIsIpfsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [imageIpfs, setImageIpfs] = useState("");
  const [selectedOption, setSelectedOption] = useState("all");
  const [planImageData, setPlanImageData] = useState<ImageData | null>(null);
  const [nftImageData, setNftImageData] = useState<ImageData | null>(null);
  const imagesRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const colorPickerRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const openColorPicker = Boolean(anchorEl);

  const [createSubscription, {loading: isCreateLoading}] =
    useMutation(CREATE_SUBSCRIPTION);
  const [updateSubscription, {loading: isUpdateLoading}] =
    useMutation(UPDATE_PLAN);
  const [generatePresignedUrlAWS] = useMutation(GENERATE_PRESIGNED_URL);
  const {data: planData, loading: planLoading} = useQuery(GET_PLAN, {
    variables: {input: {planId: selectedData?.planId}},
    skip: !isEditing || !selectedData?.planId
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: {errors, isValid, defaultValues}
  } = useForm({
    defaultValues: {
      description: [{description: ""}],
      price: "",
      name: "",
      supportableProductCount: "",
      mtdescription: "",
      planImage: null as ImageData | null,
      planColor: theme.palette.primary.main,
      nftImage: null as ImageData | null,
      renewalPeriod: "month"
    },
    mode: "onChange"
  });

  const selectedColor = useWatch({
    control,
    name: "planColor",
    defaultValue: theme.palette.primary.main
  });

  const handleColorPickerClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleColorPickerClose = () => {
    setAnchorEl(null);
  };

  const {fields, append, remove} = useFieldArray({
    control,
    name: "description"
  });

  const fetchNftMetadata = useCallback(
    async (url: string) => {
      try {
        const response = await fetch(url);
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error fetching NFT metadata:", error);
      }
    },
    [setValue]
  );

  const populateFormWithEditData = useCallback(
    async (editData: any) => {
      const [nftData, planImageData] = await Promise.all([
        fetchNftMetadata(editData.nft_media_url),
        createImageData(editData.plan_image, "plan", 1)
      ]);

      let nftImageData = null;
      if (editData.nft_image) {
        nftImageData = await createImageData(editData.nft_image, "nft", 1);
      }

      setPlanImageData(planImageData);
      setNftImageData(nftImageData);
      setImageIpfs(nftData?.image || "");

      const initialValues = {
        name: editData.name,
        description: JSON.parse(editData.description).map((desc: string) => ({
          description: desc
        })),
        price: editData.default_price.price,
        supportableProductCount:
          editData.default_price.supportable_product_count === "all"
            ? ""
            : editData.default_price.supportable_product_count,
        planImage: planImageData,
        planColor: editData.plan_color,
        nftImage: nftImageData,
        renewalPeriod: editData.default_price.renewal_period,
        mtdescription: nftData?.description || ""
      };

      setSelectedOption(
        editData.default_price.supportable_product_count === "all"
          ? "all"
          : "custom"
      );
      reset(initialValues);
      setIsDataLoading(false);
    },
    [reset, setSelectedOption, fetchNftMetadata]
  );

  const scrollToImages = useCallback(() => {
    if (imagesRef.current) {
      imagesRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
    }
  }, []);

  const handleKeyUp = useCallback(
    (_index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        e.preventDefault();
        append({description: ""});
        setTimeout(() => {
          const inputs = document.querySelectorAll(
            'input[name^="description"]'
          );
          const newInput = inputs[inputs.length - 1] as HTMLInputElement;
          newInput?.focus();
        }, 0);
      }
    },
    [append]
  );

  const uploadToAws = useCallback(
    async ({
      file,
      filePath,
      fileName
    }: UploadParams): Promise<string | null> => {
      try {
        const actualFileName =
          fileName || `image_${Date.now()}.${file.name.split(".").pop()}`;
        const presignedUrlResponse = await generatePresignedUrlAWS({
          variables: {
            input: {
              fileName: actualFileName,
              fileType: file.type,
              filePath
            }
          }
        });

        const uploadUrl =
          presignedUrlResponse.data.GeneratePresignedUrl.presignedUrl;
        const response = await fetch(uploadUrl, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": file.type
          }
        });

        if (!response.ok) {
          console.error("AWS Upload failed:", await response.text());
        }

        const awsUrl = uploadUrl.split("?")[0];

        const finalURL = awsUrl.split("?")[0]
          ? awsUrl
              .split("?")[0]
              ?.replace(process.env.WDFOLDS3URL, process.env.WDFS3URL)
          : "";

        return finalURL;
      } catch (error) {
        console.error("Error uploading to AWS:", error);
        return null;
      }
    },
    [generatePresignedUrlAWS]
  );

  useEffect(() => {
    if (isEditing && planData?.GetPlan) {
      const editData = planData.GetPlan;
      setIsDataLoading(true);
      populateFormWithEditData(editData);
    } else if (!isEditing) {
      reset({
        description: [{description: ""}],
        price: "",
        name: "",
        supportableProductCount: "",
        planImage: null,
        planColor: theme.palette.primary.main,
        nftImage: null,
        renewalPeriod: "month"
      });
      setSelectedOption("all");
    }
  }, [isEditing, planData, reset, fetchNftMetadata, populateFormWithEditData]);

  const onSubmit = useCallback(
    async (data: any) => {
      setIsIpfsLoading(true);

      const croppedPlanImageFile = await getCroppedImageFile(data.planImage);
      let croppedNftImageFile = null;

      if (data.nftImage) {
        croppedNftImageFile = await getCroppedImageFile(data.nftImage);
      }

      let planImageUrl = null;
      let nftImageUrl = null;

      if (data.planImage?.file) {
        planImageUrl = await uploadToAws({
          file: croppedPlanImageFile,
          filePath: "sponsor",
          fileName: data.planImage.file.name
        });
      }

      if (data.nftImage?.file && croppedNftImageFile) {
        nftImageUrl = await uploadToAws({
          file: croppedNftImageFile,
          filePath: "sponsor",
          fileName: data.nftImage.file.name
        });
      }

      const pinataContent = {
        description: data.mtdescription,
        external_url: process.env.API_BASE_URL?.replace("/graphql", "/"),
        image: nftImageUrl,
        name: data.name,
        attributes: [
          {trait_type: "price", value: Number(data.price)},
          {
            trait_type: "supportableProductCount",
            value:
              selectedOption === "all" ? "all" : data.supportableProductCount
          },
          {trait_type: "renewalPeriod", value: data.renewalPeriod},
          {trait_type: "planColor", value: data.planColor}
        ]
      };

      try {
        const pinataResponse = await axios.post(
          "https://api.pinata.cloud/pinning/pinJSONToIPFS",
          {pinataContent},
          {
            headers: {
              "Content-Type": "application/json",
              pinata_api_key: process.env.PINATA_API_KEY,
              pinata_secret_api_key: process.env.PINATA_API_SECRET_KEY
            }
          }
        );

        const tokenUri = `https://gateway.pinata.cloud/ipfs/${pinataResponse.data.IpfsHash}`;
        const createPlanInput = {
          name: data.name,
          description: JSON.stringify(
            data.description.map((item: any) => item.description)
          ),
          planImage: planImageUrl,
          planColor: data.planColor,
          nftImage: nftImageUrl,
          price: Number(data.price),
          recurring: isEditing ? undefined : true,
          renewalPeriod: isEditing ? undefined : data.renewalPeriod,
          renewalNumber: isEditing ? undefined : 1,
          supportableProductCount:
            selectedOption === "all" ? "all" : data.supportableProductCount,
          tokenUri
        };

        if (isEditing) {
          await updateSubscription({
            variables: {
              input: {...createPlanInput, planId: selectedData.planId}
            }
          });
        } else {
          await createSubscription({
            variables: {input: createPlanInput}
          });
        }
        handleClose();
        refetch();
      } catch (error) {
        console.error("Error submitting plan:", error);
      } finally {
        setIsIpfsLoading(false);
      }
    },
    [
      isEditing,
      selectedData,
      imageIpfs,
      selectedOption,
      createSubscription,
      updateSubscription,
      handleClose,
      refetch
    ]
  );

  const handleImageChange = useCallback(
    (
      type: "plan" | "nft",
      newImage: File,
      imageSrc: string | null,
      croppedArea: Area | null,
      croppedAreaPixels: Area | null
    ) => {
      const imageData: ImageData = {
        file: newImage,
        croppedArea,
        croppedAreaPixels,
        preview: imageSrc
      };

      if (type === "plan") {
        setValue("planImage", imageData);
      } else {
        setValue("nftImage", imageData);
      }
    },
    [setValue, scrollToImages]
  );

  const getCroppedImageStyle = useCallback(
    (image: ImageData | null) => {
      if (!image || !image.croppedArea) return {};

      const {croppedArea} = image;
      const {width} = croppedArea;

      const scale = 100 / width;
      const transform = {
        x: `${-croppedArea.x * scale}%`,
        y: `${-croppedArea.y * scale}%`,
        scale,
        width: "calc(100% + 0.5px)",
        height: "auto"
      };

      const imageStyle = {
        transform: `translate3d(${transform.x}, ${transform.y}, 0) scale3d(${transform.scale},${transform.scale},1)`,
        width: transform.width,
        height: transform.height,
        position: "absolute",
        transformOrigin: "top left",
        top: 0,
        left: 0
      };

      return imageStyle;
    },
    [watch("planImage"), watch("nftImage")]
  );

  const planImageStyle = useMemo(
    () => getCroppedImageStyle(watch("planImage")),
    [getCroppedImageStyle, watch]
  );
  const nftImageStyle = useMemo(
    () => getCroppedImageStyle(watch("nftImage")),
    [getCroppedImageStyle, watch]
  );

  const isSubmitDisabled =
    !isValid || isIpfsLoading || isCreateLoading || isUpdateLoading;

  if (planLoading || isDataLoading) {
    return <SuspenseLoader />;
  }

  return (
    <StyledDialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        {isEditing ? "Edit Subscription Plan" : "Create New Subscription Plan"}
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={8}>
              <Box mb={3}>
                <Typography variant="h6" gutterBottom>
                  Basic Details
                </Typography>
                <Box display="flex" gap={2}>
                  <Controller
                    name="name"
                    control={control}
                    rules={{required: "Name is required"}}
                    render={({field, fieldState: {error}}) => (
                      <TextField
                        {...field}
                        label="Plan Name"
                        fullWidth
                        margin="normal"
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />

                  <Controller
                    name="planColor"
                    control={control}
                    render={({field}) => (
                      <ColorPickerWrapper>
                        <Box display="flex" alignItems="center">
                          <Box
                            ref={colorPickerRef}
                            onClick={handleColorPickerClick}
                            sx={{
                              width: 36,
                              height: 36,
                              backgroundColor: field.value,
                              cursor: "pointer",
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                              transition: "transform 0.2s ease-in-out",
                              "&:hover": {
                                transform: "scale(1.05)"
                              }
                            }}
                          />
                          <TextField
                            {...field}
                            label="Plan Color"
                            variant="outlined"
                            size="small"
                            sx={{ml: 2, width: "120px"}}
                          />
                          <Popper
                            open={openColorPicker}
                            anchorEl={anchorEl}
                            placement="bottom-start"
                            transition
                            style={{zIndex: 1300}}
                          >
                            {({TransitionProps}) => (
                              <Grow
                                {...TransitionProps}
                                style={{transformOrigin: "left top"}}
                                timeout={250}
                              >
                                <Paper>
                                  <ClickAwayListener
                                    onClickAway={handleColorPickerClose}
                                  >
                                    <Box display="flex" flexDirection="column">
                                      <Box p={2}>
                                        <HexColorPicker
                                          color={field.value}
                                          onChange={(color) => {
                                            field.onChange(color);
                                            // handleColorPickerClose();
                                          }}
                                        />
                                      </Box>
                                      <Box mb={2} pl={2} pr={2}>
                                        <IconButton
                                          size="small"
                                          onClick={handleColorPickerClose}
                                          aria-label="close color picker"
                                          sx={{
                                            width: "100%"
                                          }}
                                        >
                                          <CheckIcon fontSize="small" />
                                        </IconButton>
                                      </Box>
                                    </Box>
                                  </ClickAwayListener>
                                </Paper>
                              </Grow>
                            )}
                          </Popper>
                        </Box>
                      </ColorPickerWrapper>
                    )}
                  />
                </Box>

                <Box display="flex" gap={2}>
                  <Controller
                    name="price"
                    control={control}
                    rules={{required: "Price is required"}}
                    render={({field, fieldState: {error}}) => (
                      <TextField
                        {...field}
                        label="Price"
                        fullWidth
                        margin="normal"
                        type="number"
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />

                  <Controller
                    name="renewalPeriod"
                    control={control}
                    disabled={!(isDataLoading && planLoading) && isEditing}
                    rules={{
                      required: "Renewal Period is required"
                    }}
                    render={({field}) => {
                      return (
                        <FormControl variant="outlined" fullWidth margin="normal">
                          <InputLabel id="renewal-period-label">
                            Renewal Period
                          </InputLabel>
                          <Tooltip
                            title={
                              isEditing
                                ? "Plan frequency cannot be changed. You can create a new plan instead."
                                : undefined
                            }
                          >
                            <Select
                              labelId="renewal-period-label"
                              label="Renewal Period"
                              value={field.value}
                              {...field}
                            >
                              {["month", "year"].map((option) => (
                                <MenuItem key={option} value={option}>
                                  {option.charAt(0).toUpperCase() +
                                    option.slice(1)}
                                </MenuItem>
                              ))}
                            </Select>
                          </Tooltip>
                        </FormControl>
                      );
                    }}
                  />
                </Box>
              </Box>

              <Box mb={3}>
                <Typography variant="h6" gutterBottom>
                  Features
                </Typography>
                {fields.map((item, index) => (
                  <Box
                    key={item.id}
                    display="flex"
                    alignItems="center"
                    mb={1}
                    gap="1rem"
                  >
                    <Controller
                      name={`description.${index}.description`}
                      control={control}
                      rules={{required: "Description is required"}}
                      render={({field, fieldState: {error}}) => (
                        <TextField
                          {...field}
                          label={`Feature ${index + 1}`}
                          fullWidth
                          margin="normal"
                          error={!!error}
                          helperText={error?.message}
                          onKeyUp={(e) =>
                            handleKeyUp(
                              index,
                              e as React.KeyboardEvent<HTMLInputElement>
                            )
                          }
                        />
                      )}
                    />
                    <IconButton onClick={() => remove(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))}
                <Button
                  onClick={() => append({description: ""})}
                  startIcon={<AddTwoToneIcon />}
                >
                  Add Feature
                </Button>
              </Box>

              <Box mb={3} ref={imagesRef}>
                <Typography variant="h6" gutterBottom>
                  Images
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Controller
                      name="planImage"
                      control={control}
                      render={({field}) => {
                        return (
                          <ImageUpload
                            onImageChange={(
                              file,
                              imageSrc,
                              croppedArea,
                              croppedAreaPixels
                            ) => {
                              handleImageChange(
                                "plan",
                                file,
                                imageSrc,
                                croppedArea,
                                croppedAreaPixels
                              );
                            }}
                            imageData={planImageData}
                            image={field.value?.preview || null}
                            placeholderImage={IconImagePlaceholder}
                            preset="icon"
                            type="Plan"
                            scrollToImage={scrollToImages}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="nftImage"
                      control={control}
                      render={({field}) => (
                        <ImageUpload
                          onImageChange={(
                            file,
                            imageSrc,
                            croppedArea,
                            croppedAreaPixels
                          ) => {
                            handleImageChange(
                              "nft",
                              file,
                              imageSrc,
                              croppedArea,
                              croppedAreaPixels
                            );
                          }}
                          imageData={nftImageData}
                          image={field.value?.preview || null}
                          placeholderImage={NFTImagePlaceholder}
                          preset="nft"
                          type="NFT"
                          scrollToImage={scrollToImages}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box mb={3}>
                <Typography variant="h6" gutterBottom>
                  NFT Description
                </Typography>
                <Controller
                  name="mtdescription"
                  control={control}
                  rules={{required: "NFT Metadata Description is required"}}
                  render={({field, fieldState: {error}}) => (
                    <TextField
                      {...field}
                      label="NFT Metadata Description"
                      fullWidth
                      margin="normal"
                      multiline
                      rows={4}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </Box>

              <Box mb={3}>
                <Typography variant="h6" gutterBottom>
                  Product Count
                </Typography>
                <RadioGroup
                  value={selectedOption}
                  onChange={(e) => setSelectedOption(e.target.value)}
                >
                  <FormControlLabel
                    value="all"
                    control={<Radio />}
                    label="All Products"
                  />
                  <FormControlLabel
                    value="custom"
                    control={<Radio />}
                    label="Custom Count"
                  />
                </RadioGroup>
                {selectedOption === "custom" && (
                  <Controller
                    name="supportableProductCount"
                    control={control}
                    rules={{
                      required: "Product count is required for custom option"
                    }}
                    render={({field, fieldState: {error}}) => (
                      <TextField
                        {...field}
                        label="Number of Products"
                        type="number"
                        fullWidth
                        margin="normal"
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box
                position="sticky"
                top={0.5}
              >
                <Typography variant="h6" gutterBottom>
                  Plan Preview
                </Typography>
                <PreviewCard type="plan">
                  <PreviewHeader backgroundColor={selectedColor}>
                    <IconWrapper>
                      <img
                        src={
                          watch("planImage")?.preview || IconImagePlaceholder
                        }
                        alt="Plan Preview"
                        style={{...planImageStyle}}
                      />
                    </IconWrapper>
                    <Typography variant="h3" color="#fff">
                      {watch("name") || "Plan name"}
                    </Typography>
                  </PreviewHeader>
                  <CardContent>
                    {watch("description").map((desc, index) => (
                      <FeatureItem key={index}>
                        <CheckIcon color="primary" sx={{mr: 1}} />
                        <Typography variant="body2">
                          {desc.description || `Feature ${index + 1}`}
                        </Typography>
                      </FeatureItem>
                    ))}
                    <PriceWrapper>
                      <Typography
                        variant="h2"
                        component="span"
                        sx={{fontWeight: "bold"}}
                      >
                        ${watch("price") || "0"}
                      </Typography>
                      <Typography
                        variant="body1"
                        component="span"
                        sx={{marginLeft: 1}}
                      >
                        /per {watch("renewalPeriod") || defaultValues?.renewalPeriod}
                      </Typography>
                    </PriceWrapper>
                    <Button variant="contained" fullWidth sx={{mt: 2}}>
                      Support Now
                    </Button>
                  </CardContent>
                </PreviewCard>

                <Typography variant="h6" gutterBottom>
                  NFT Preview
                </Typography>
                <PreviewCard>
                  <Box
                    sx={{
                      paddingBottom: watch("nftImage") ? "100%" : "0",
                      marginBottom: watch("nftImage") ? "0" : "-6px",
                      position: "relative",
                      overflow: "hidden"
                    }}
                  >
                    <img
                      src={watch("nftImage")?.preview || NFTImagePlaceholder}
                      alt="NFT Preview"
                      style={{...nftImageStyle}}
                    />
                  </Box>
                </PreviewCard>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitDisabled}
          >
            {isEditing ? "Update Plan" : "Create Plan"}
          </Button>
        </DialogActions>
      </form>
      {isIpfsLoading && <SuspenseLoader left="0%" />}
    </StyledDialog>
  );
};

export default SubmitEditPlanDialog;
