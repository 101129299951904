import gql from "graphql-tag";

const GET_ALL_VIDEO = gql`
  query findAllVideo($input: FilterVideoInput!) {
    findAllVideo(filterVideoInput: $input) {
      videos {
        _id
        dog_name
        dog_id
        aws_key
        aws_url
        mimetype
        rfid
        location
        epoch
        thumbnail_url
        time
        createdAt
        event
        video_presets
        is_active
      }
      totalResults
      pages
    }
  }
`;

const GET_VIDEO_DETAILS = gql`
  query findOneVideo($input: FindOneVideoInput!) {
    findOneVideo(findOneVideoInput: $input) {
      _id
      dog_name
      dog_id
      aws_key
      aws_url
      aws_stream_url
      mimetype
      rfid
      location
      epoch
      thumbnail_url
      time
      createdAt
      event
      video_presets
      is_active
    }
  }
`;

const REMOVE_VIDEO = gql`
  mutation RemoveVideo($input: RemoveVideoInput!) {
    removeVideo(removeVideoInput: $input) {
      _id
    }
  }
`;

const GET_ALL_LOCATIONS = gql`
  query findAllCameraLocation {
    findAllCameraLocation(filterVideoInput: {}) {
      camera_locations {
        nvr
        location
      }
    }
  }
`;

const UPDATE_VIDEO = gql`
  mutation UpdateVideo($input: UpdateVideoInput!) {
    updateVideo(updateVideoInput: $input) {
      success
      message
      video {
        _id
        video_presets
      }
    }
  }
`;

export {GET_ALL_VIDEO, GET_VIDEO_DETAILS, REMOVE_VIDEO, GET_ALL_LOCATIONS, UPDATE_VIDEO};