// web-react-admin-lavabee\src\shared\components\ImageUpload\index.tsx
import React, { useState, useCallback, useRef } from 'react';
import { Box, Typography, Slider, IconButton, Paper, Button, Alert } from '@mui/material';
import Cropper, { Area } from 'react-easy-crop';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import UploadIcon from '@mui/icons-material/Upload';
import { ImageUploadBox, CropContainer, Controls } from './index.styles';

interface ImageConfig {
  acceptedTypes: string[];
  minWidth: number;
  minHeight: number;
  maxWidth: number;
  maxHeight: number;
  maxFileSize: number; // in bytes
  aspectRatio: number;
}

const presets: { [key: string]: ImageConfig } = {
  icon: {
    acceptedTypes: ['image/jpeg', 'image/png', 'image/svg+xml'],
    minWidth: 32,
    minHeight: 32,
    maxWidth: 1024,
    maxHeight: 1024,
    maxFileSize: 1 * 1024 * 1024, // 1MB
    aspectRatio: 1,
  },
  nft: {
    acceptedTypes: ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml'],
    minWidth: 300,
    minHeight: 300,
    maxWidth: 4000,
    maxHeight: 4000,
    maxFileSize: 2 * 1024 * 1024, // 2MB
    aspectRatio: 1,
  },
  default: {
    acceptedTypes: ['image/jpeg', 'image/png', 'image/gif'],
    minWidth: 100,
    minHeight: 100,
    maxWidth: 4000,
    maxHeight: 4000,
    maxFileSize: 5 * 1024 * 1024, // 5MB
    aspectRatio: 16 / 9,
  },
};

type PresetKey = keyof typeof presets;

interface ImageUploadProps {
  onImageChange: (file: File, imageSrc: string | null, croppedArea: Area | null, croppedAreaPixels: Area | null) => void;
  imageData: any | null;
  image: string | null;
  placeholderImage: string;
  preset?: PresetKey | ImageConfig;
  type?: string;
  scrollToImage?: () => void;
}

const ImageUpload: React.FC<ImageUploadProps> = ({
  onImageChange,
  image,
  imageData,
  placeholderImage,
  preset = 'default',
  type = 'Image',
  scrollToImage,
}) => {
  const config: ImageConfig = typeof preset === 'object' ? preset : presets[preset as PresetKey];

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [error, setError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [originalFile, setOriginalFile] = useState<File | null>(imageData?.file);

  const validateImage = (file: File): Promise<{ error: string | null; imageSrc: string | null }> => {
    return new Promise((resolve) => {
      if (!config.acceptedTypes.includes(file.type)) {
        resolve({
          error: `Invalid file format. Only ${config.acceptedTypes.map(type => type.split('/')[1].toUpperCase()).join(', ')} are allowed.`,
          imageSrc: null
        });
        return;
      }

      if (file.size > config.maxFileSize) {
        resolve({
          error: `Image must not exceed ${config.maxFileSize / (1024 * 1024)}MB`,
          imageSrc: null
        });
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          if (img.width < config.minWidth || img.height < config.minHeight) {
            resolve({
              error: `Image must be at least ${config.minWidth}x${config.minHeight} pixels`,
              imageSrc: null
            });
          } else if (img.width > config.maxWidth || img.height > config.maxHeight) {
            resolve({
              error: `Image must not exceed ${config.maxWidth}x${config.maxHeight} pixels`,
              imageSrc: null
            });
          } else {
            resolve({
              error: null,
              imageSrc: e.target?.result as string
            });
          }
        };
        img.onerror = () => {
          resolve({
            error: "Failed to load image. Please try another file.",
            imageSrc: null
          });
        };
        img.src = e.target?.result as string;
      };
      reader.onerror = () => {
        resolve({
          error: "Failed to read file. Please try again.",
          imageSrc: null
        });
      };
      reader.readAsDataURL(file);
    });
  };

  const handleImageLoad = useCallback(async (file: File) => {
    const { error, imageSrc } = await validateImage(file);

    if (error) {
      setError(error);
      return;
    }

    setError(null);
    setOriginalFile(file);
    onImageChange(file, imageSrc, null, null);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    scrollToImage();
  }, [onImageChange]);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      await handleImageLoad(event.target.files[0]);
    }
  };

  const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const handleDrop = useCallback(async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      await handleImageLoad(event.dataTransfer.files[0]);
    }
  }, [handleImageLoad]);

  const handleReupload = () => {
    fileInputRef.current?.click();
  };

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    const file = originalFile || imageData?.file;

    if (image && file) {
      const fileName = file.name;
      const fileExtension = fileName.split('.').pop()?.toLowerCase() || 'jpg';
      const mimeType = file.type || `image/${fileExtension}`;
      const croppedFileName = `cropped_${fileName}`;

      onImageChange(
        new File([file], croppedFileName, { type: mimeType }),
        image,
        croppedArea,
        croppedAreaPixels,
      );
    }
  }, [onImageChange, image, originalFile, imageData]);

  return (
    <Paper
      elevation={3}
      sx={{ p: 2, bgcolor: 'background.default' }}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <input
        ref={fileInputRef}
        accept={config.acceptedTypes.join(',')}
        id={`${type.toLowerCase()}-image-upload`}
        type="file"
        hidden
        onChange={handleFileChange}
      />
      {!image ? (
        <ImageUploadBox>
          <label htmlFor={`${type.toLowerCase()}-image-upload`}>
            <Box
              component="img"
              src={placeholderImage}
              alt={`${type} Image`}
              sx={{ width: '100%', height: 'auto', maxHeight: 200, objectFit: 'contain' }}
            />
            <Typography variant="body2" sx={{ mt: 2, color: 'text.secondary' }}>
              <b>Upload {type} Image</b>
              <br />
              Drag & drop or click to upload. Accepted formats: {config.acceptedTypes.map(type => type.split('/')[1].toUpperCase()).join(', ')}
              <br />
              Min size: {config.minWidth}x{config.minHeight}px, Max size: {config.maxWidth}x{config.maxHeight}px, Max file size: {config.maxFileSize / (1024 * 1024)}MB
            </Typography>
          </label>
        </ImageUploadBox>
      ) : (
        <Box display="flex" flexDirection="column">
          <CropContainer sx={{ height: 400, mb: 2 }}>
            <Cropper
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={config.aspectRatio}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </CropContainer>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'left', gap: "0.5rem" }}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              <Box component="span" fontWeight="bold">Zoom:</Box> Use scroll or slider
              <br />
              <Box component="span" fontWeight="bold">Move:</Box> Click and drag image
              <br />
              <Box component="span" fontWeight="bold">Drag & drop</Box> new image to replace
            </Typography>
          </Box>
          <Controls>
            <IconButton onClick={() => setZoom(z => Math.max(1, z - 0.1))} size="large">
              <ZoomOutIcon />
            </IconButton>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-label="Zoom"
              onChange={(_, newZoom) => setZoom(newZoom as number)}
              sx={{ mx: 2, width: 'calc(100% - 96px)' }}
            />
            <IconButton onClick={() => setZoom(z => Math.min(3, z + 0.1))} size="large">
              <ZoomInIcon />
            </IconButton>
          </Controls>
          <Button
            variant="outlined"
            startIcon={<UploadIcon />}
            onClick={handleReupload}
            sx={{ alignSelf: 'center', mt: 2 }}
          >
            Upload New Image
          </Button>
        </Box>
      )}
      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
    </Paper>
  );
};

export default ImageUpload;