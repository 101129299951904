import {useState, useEffect} from "react";
import {useNavigate} from "react-router";

import {
  Grid,
  Box,
  IconButton,
  Paper,
  Typography,
  Stack,
  useTheme,
  alpha,
  CircularProgress,
  Tooltip,
  Button,
  Alert
} from "@mui/material";

import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SaveIcon from "@mui/icons-material/Save";

import DogHouseImg from "../../assets/images/DogHouse.png";

import Label from "../../shared/components/Label";

enum DogCatalogType {
  ALL = 0,
  MY_DOGS = 1
}

interface DogCatalogProps {
  title: string;
  type: number;
  data: any[];
  isLoading: boolean;
  isMyDogsModified?: boolean;
  handleRemove?: (selectedDogId: string) => void;
  handleAdd?: (selectedDogId: string) => void;
  handleSave?: () => void;
}

const DogCatalog = ({
  title,
  type,
  data,
  isLoading,
  isMyDogsModified,
  handleRemove,
  handleAdd,
  handleSave
}: DogCatalogProps) => {
  const theme = useTheme();

  const [dogList, setDogList] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      setDogList(data);
    }
  }, [data]);

  const DogView = ({dog}) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleClickView = (dogId: string) => {
      navigate(`/dogdetails/${dogId}`);
    };

    type Color =
      | "error"
      | "info"
      | "secondary"
      | "primary"
      | "warning"
      | "success";
    const getStatusLabel = (
      status: "active" | "inactive" | "suspended" | "atheaven" | "adopted"
    ): JSX.Element => {
      let color = "";
      let text = "";
      switch (status) {
        case "active":
          text = "Active";
          color = "info";
          break;
        case "inactive":
          text = "Inactive";
          color = "secondary";
          break;
        case "suspended":
          text = "Suspended";
          color = "error";
          break;
        case "atheaven":
          text = "At Heaven";
          color = "error";
          break;
        case "adopted":
          text = "Adopted";
          color = "success";
          break;
        default:
          color = "warning";
          text = "Inactive";
          break;
      }
      return <Label color={color as Color}>{text}</Label>;
    };

    const truncateString = (text: string) => {
      if (text.length > 12) {
        return text.substring(0, 12) + "...";
      }

      return text?.slice(0, 1)?.toUpperCase() + text?.slice(1)?.toLowerCase();
    };
    return (
      <Grid item xs={12} sm={6} md={4} lg={2} style={{position: "relative"}}>
        <Stack
          height="100%"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {dog ? (
            <Box
              sx={{
                width: "100%",
                paddingBottom: "100%",
                position: "relative",
                display: "inline-block",
                cursor: "pointer",
                transition: "all 0.2s ease-in-out",
                "&:hover": {
                  transform: "scale(1.03)",
                  boxShadow: (theme) => theme.shadows[10]
                }
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleClickView(dog.custom_id);
              }}
            >
              <img
                src={dog.image}
                style={{
                  borderRadius: 24,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  border: `6px solid ${theme.palette.background.default}`
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: isLoading
                    ? alpha(theme.palette.background.default, 0.5)
                    : "transparent",
                  opacity: isLoading ? 1 : 0,
                  transition: "opacity 0.3s",
                  zIndex: isLoading ? 1 : 0
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  bottom: 15,
                  right: 15,
                  display: "flex",
                  opacity: isHovered ? 1 : 0,
                  transition: "opacity 0.3s"
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                {!isLoading && (
                  <>
                    <Tooltip title="View Dog Details">
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleClickView(dog.custom_id);
                        }}
                        component="label"
                        sx={{
                          background: alpha(theme.palette.primary.main, 0.5),
                          color: "#fff",
                          "&:hover": {
                            backgroundColor: theme.palette.primary.main
                          }
                        }}
                        color="inherit"
                        size="small"
                      >
                        <VisibilityIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>

                    {type === DogCatalogType.ALL ? (
                      <Tooltip title="Add Dog">
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            handleAdd(dog._id);
                          }}
                          component="label"
                          sx={{
                            marginLeft: 1,
                            background: alpha(theme.palette.success.main, 0.5),
                            color: "#fff",
                            "&:hover": {
                              backgroundColor: theme.palette.success.main
                            }
                          }}
                          color="inherit"
                          size="small"
                        >
                          <AddCircleOutlineIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Remove Dog from this plan">
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemove(dog._id);
                          }}
                          component="label"
                          sx={{
                            marginLeft: 1,
                            background: alpha(theme.palette.error.main, 0.5),
                            color: "#fff",
                            "&:hover": {
                              backgroundColor: theme.palette.error.main
                            }
                          }}
                          color="inherit"
                          size="small"
                        >
                          <DeleteTwoToneIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </>
                )}
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "24px",
                position: "relative"
              }}
            >
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 512 512"
                fill={theme.palette.background.default}
                style={{
                  borderRadius: 24,
                  border: `6px solid #d2dcea`
                }}
              >
                <rect width="512" height="512" />
              </svg>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"
                }}
              >
                <img src={DogHouseImg} width={80} />
              </Box>
            </Box>
          )}
          {dog && (
            <Stack
              px={1}
              py={0.5}
              direction="row"
              justifyContent="space-between"
            >
              <Typography
                variant="h6"
                style={{
                  fontSize: 16,
                  fontWeight: 700,
                  textAlign: "center",
                  color: isLoading
                    ? theme.palette.text.disabled
                    : theme.palette.text.primary
                }}
              >
                {truncateString(dog.name)}
              </Typography>
              <Typography
                variant="body1"
                style={{
                  textAlign: "center"
                }}
              >
                {getStatusLabel(dog.status) || "N/A"}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Grid>
    );
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Stack gap={2} direction="row" alignItems="center" height="100%">
          <Typography
            variant="h6"
            sx={{fontWeight: "bold", my: 1, fontSize: 16}}
          >
            {title}
          </Typography>
          {(!dogList.length && type === DogCatalogType.MY_DOGS) && (
            <Alert variant="outlined" severity="info">You don't have any dog selected, add one in the dog catalog below</Alert>
          )}
        </Stack>
        {type === DogCatalogType.MY_DOGS && (
          <Button
            onClick={handleSave}
            disabled={isLoading || isMyDogsModified}
            color="primary"
            variant="contained"
            size="large"
            startIcon={
              isLoading ? (
                <CircularProgress size={16} sx={{color: "#929bab"}} />
              ) : (
                <SaveIcon />
              )
            }
          >
            Save
          </Button>
        )}
      </Stack>

      <Paper elevation={3} sx={{padding: 1.5, mt: 2, mb: 2}}>
        <Grid container spacing={2}>
          {dogList &&
            dogList.map((dog, index) => <DogView dog={dog} key={index} />)}

          {!dogList.length && <DogView dog={null} key={null} />}
        </Grid>
      </Paper>
    </>
  );
};

export default DogCatalog;
