import { Fragment, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { optimizeImageURL } from "../../shared/utilities/helpers";

import QRCode from "react-qr-code";
import JSZip from "jszip";

import { useLazyQuery, useMutation } from "@apollo/client";

import {
  DELETE_PRODUCT,
  UPDATE_PRODUCT,
  GET_PRODUCT_DETAILS,
  UPDATE_RFID,
} from "../../shared/graphQL/dog/queries";
import { GENERATE_PRESIGNED_URL } from "../../shared/graphQL/common/queries";

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  InputLabel,
  Link as MuiLink,
  Paper,
  Stack,
  TextField,
  Typography,
  useTheme,
  alpha,
  CircularProgress,
} from "@mui/material";

import {
  CloudDownload,
  ContentCopy,
  DeleteTwoTone,
  EditTwoTone,
  Info,
  PhotoCamera,
  ErrorOutline,
  CheckCircle
} from "@mui/icons-material";

import DialogComponent from "../../shared/components/Dialog";
import Label from "../../shared/components/Label";
import DogGallery, { DogGalleryType } from "./DogGallery";
import ViewMediaDialog from "../../shared/components/VideoMediaDialog";
import CropModal from "../../shared/components/CropModal";

const instructions = [
  "Open the NFC Tools application and locate the 'Write' button",
  "Click on the 'Write' button and a prompt will appear",
  "Select 'Add a record' from the prompt options",
  "On the new screen, click on the text field and enter the dog ID",
  "After entering the dog ID, confirm the input and return to the previous screen",
  "On the previous screen, click 'OK' to save the record. A new record will appear at the bottom",
  "Locate the 'Write/11 bytes' option and select it",
  "Now, prepare to scan the NFC Tag by positioning it near the back of your phone",
  "The NFC Tools application will automatically close after successfully scanning the NFC Tag",
];

const verificationSteps = [
  "After clicking on the 'Write' button, enter the dog ID in the designated field",
  "Then, click on the 'Read' button to display the NFC Tag along with the associated dog ID",
];

const DogDetails = () => {
  const theme = useTheme();
  const { id } = useParams();
  const [openDelete, setOpenDelete] = useState(false);
  const [productData, setProductData] = useState(null);
  const [
    getProductDetails,
    { data: getProductDetailsData, loading: productDetailsLoading, refetch },
  ] = useLazyQuery(GET_PRODUCT_DETAILS);
  const [deleteProduct, { data: deleteProductData }] =
    useMutation(DELETE_PRODUCT);
  const [updateProduct, { data: updateProductData }] =
    useMutation(UPDATE_PRODUCT);
  const [updateRFID, { data: updateRFIDData }] = useMutation(UPDATE_RFID);
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [openRfidg, setOpenRfid] = useState(false);
  const [rfidError, setRfidError] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [clickedImage, setClickedImage] = useState(null);
  const [presignedURL, setPresignedURL] = useState(null);
  const [uploadingType, setUploadingType] = useState(null);
  const [profilePicSrc, setProfilePicSrc] = useState(null);
  const [isProfilePicSrcLoading, setIsProfilePicSrcLoading] = useState(false);
  const [profilePicURL, setProfilePicURL] = useState(null);
  const [isUploadingProfilePic, setIsUploadingProfilePic] = useState(false);
  const [isUploadingCover, setIsUploadingCover] = useState(false);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [openCropModal, setCropModal] = useState(false);
  const [openCroppedModal, setCroppedModal] = useState(false);
  const [imageModal, setImageModal] = useState(false);
  const [coverURL, setCoverURL] = useState(null);
  const [generatePresignedUrl] = useMutation(GENERATE_PRESIGNED_URL);

  const wdfs3URL = process.env.WDFS3URL;
  const wdfs3oldURL = process.env.WDFOLDS3URL;

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();

  const setCroppedImageUrlCallback = useCallback(
    (url) => {
      setCroppedImageUrl(url);
      setCroppedModal(true);
    },
    [croppedImageUrl]
  );

  useEffect(() => {
    getProductDetails({ variables: { input: { customId: id } } });
  }, []);

  useEffect(() => {
    if (getProductDetailsData) {
      const newProductData = JSON.parse(
        getProductDetailsData.retrieveProduct.productData
      );

      setProductData(newProductData);
      setCoverURL(newProductData.basicInformation.coverphoto);
      setProfilePicURL(newProductData.image);
    }
  }, [getProductDetailsData]);

  useEffect(() => {
    if (deleteProductData) {
      navigate(`/dog`);
    }
  }, [deleteProductData]);

  useEffect(() => {
    if (presignedURL) {
      setCoverURL(presignedURL);

      const input = {
        coverphoto: presignedURL,
      };
      const parsedInput = `[${JSON.stringify(input)}]`;
      const payload = {
        basicInformation: parsedInput,
      };
      updateProduct({
        variables: { id: { id: productData._id }, input: payload },
      });
    }
  }, [presignedURL]);

  useEffect(() => {
    if (croppedImageUrl) {
      setProfilePicURL(croppedImageUrl);

      const input = {
        image: croppedImageUrl,
      };
      const parsedInput = `[${JSON.stringify(input)}]`;
      const payload = {
        entity: parsedInput,
      };
      updateProduct({
        variables: { id: { id: productData._id }, input: payload },
      });
    }
  }, [croppedImageUrl]);

  useEffect(() => {
    if (!updateProductData) return;

    if (uploadingType === "cover") {
      setIsUploadingCover(false);
    } else {
      setIsUploadingProfilePic(false);
    }

    setUploadingType(null);
  }, [updateProductData]);

  useEffect(() => {
    if (updateRFIDData) {
      refetch();
      setOpenRfid(false);
    }
  }, [updateRFIDData]);

  const formatDate = (dateToFormat) => {
    if (dateToFormat) {
      const date = new Date(dateToFormat);
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      const formattedDate = `${day}-${month}-${year}`;
      return formattedDate;
    } else {
      return "";
    }
  };

  type Color =
    | "error"
    | "info"
    | "secondary"
    | "primary"
    | "warning"
    | "success";
  const getStatusLabel = (
    status: "active" | "inactive" | "suspended" | "atheaven" | "adopted"
  ): JSX.Element => {
    let color = "";
    let text = "";
    switch (status) {
      case "active":
        text = "Active";
        color = "info";
        break;
      case "inactive":
        text = "Inactive";
        color = "secondary";
        break;
      case "suspended":
        text = "Suspended";
        color = "error";
        break;
      case "atheaven":
        text = "At Heaven";
        color = "error";
        break;
      case "adopted":
        text = "Adopted";
        color = "success";
        break;
      default:
        color = "warning";
        text = "Inactive";
        break;
    }
    return <Label color={color as Color}>{text}</Label>;
  };

  const handleDeleteClick = (row) => {
    setOpenDelete(true);
  };

  const handleClose = (rw) => {
    setOpenDelete(false);
    setOpenRfid(false);
  };

  const handleDelete = () => {
    deleteProduct({ variables: { id: { id: productData?._id } } });
    setOpenDelete(false);
  };

  const handleEditClick = (row) => {
    navigate("/dog/stepper", { state: { row } });
  };

  const handleRFID = () => {
    setOpenRfid(true);

    if (productData?.rfid_tag) {
      setValue("rfidData", productData?.rfid_tag, { shouldValidate: true });
    }
  };

  const handleOpenViewImage = (imageUrl) => {
    setOpenImage(true);
    setClickedImage(imageUrl);
  };

  const handleCloseViewImage = () => {
    setOpenImage(false);
    setClickedImage(null);
  };

  const downloadFile = (fileUrl, fileName) => {
    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("File download failed:", error);
      });
  };

  const downloadFilesAsZip = async (fileUrls) => {
    const zip = new JSZip();

    for (let i = 0; i < fileUrls.length; i++) {
      const fileUrl = fileUrls[i];
      const response = await fetch(fileUrl);
      const fileBlob = await response.blob();
      const fileName = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
      zip.file(fileName, fileBlob);
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      const url = URL.createObjectURL(content);
      const link = document.createElement("a");
      link.href = url;
      link.download = "files.zip";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    });
  };

  const formatValue = (value) => {
    const isDateUrl = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(
      value
    );
    const isURL = /^(ftp|http|https):\/\/[^ "]+$/;

    if (typeof value === "object" && value !== null) {
      return Object.values(value).join("");
    }

    if (isURL.test(value) && !Array.isArray(value)) {
      const fileName = value.substring(value.lastIndexOf("/") + 1);
      return (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => downloadFile(value, fileName)}
        >
          <CloudDownload />
        </span>
      );
    }

    if (Array.isArray(value) && value.every((item) => isURL.test(item))) {
      return (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => downloadFilesAsZip(value)}
        >
          <CloudDownload />
        </span>
      );
    }

    if (isDateUrl) {
      const date = new Date(value);
      const formattedDate = `${date.getUTCDate()}-${
        date.getUTCMonth() + 1
      }-${date.getUTCFullYear()}`;
      return formattedDate;
    }
    return value;
  };

  const handleInfoClick = () => {
    setOpenDialog(true);
  };

  const onSubmit = (data) => {
    updateRFID({
      variables: {
        input: { productId: productData._id, rfidTag: data.rfidData },
      },
    });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const copyToClipboard = useCallback((text) => {
    navigator.clipboard.writeText(text);
  }, []);

  const InstructionListItem = ({ text }) => (
    <li>
      <Typography variant="body1" sx={{ fontWeight: 500, fontSize: 16 }}>
        {text}
      </Typography>
    </li>
  );

  const handleQRCodeClick = () => {
    const qrCodeSvgElement = document.querySelector("#qrCode");
    if (qrCodeSvgElement) {
      const svgContent = new XMLSerializer().serializeToString(
        qrCodeSvgElement
      );
      const dataUrl = `data:image/svg+xml;base64,${btoa(svgContent)}`;
      const newWindow = window.open("", "_blank");
      if (newWindow) {
        newWindow.document.write(`
          <html>
            <head>
              <title>QR Code</title>
            </head>
            <body style="display: flex; justify-content: center; align-items: center; min-height: 100vh; margin: 0;">
              <img src="${dataUrl}" width="500" height="500" />
            </body>
          </html>
        `);
        newWindow.document.close();
      } else {
        alert(
          "Popup blocked. Please allow pop-ups for this site and try again."
        );
      }
    }
  };

  const handleFileCover = async (event: any, type: string) => {
    event.preventDefault();
    setUploadingType(type);
    setIsUploadingCover(true);

    const file = event.target.files[0];
    if (!file) return;

    let payload = {
      fileName: file?.name,
      fileType: file.type,
      filePath: "sponsor",
    };

    const response = await generatePresignedUrl({
      variables: { input: payload },
    });

    const fileURL = response.data.GeneratePresignedUrl.presignedUrl;

    await fetch(fileURL, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": file.type,
      },
    });

    const finalURL = fileURL.split("?")[0]
      ? fileURL.split("?")[0]?.replace(wdfs3oldURL, wdfs3URL)
      : "";

    setPresignedURL(finalURL);
  };

  const handleFilePfp = (e: any, type: string) => {
    if (e.target.files && e.target.files.length > 0) {
      setUploadingType(type);
      setIsUploadingProfilePic(true);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        const img = new Image();

        img.addEventListener("load", () => {
          const width = img.width;
          const height = img.height;

          if (width < 300 || height < 300) {
            setImageModal(true);
          } else {
            setProfilePicSrc(reader.result as string);
            setCropModal(true);
          }
        });

        img.src = reader.result as string;
      });

      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleCloseCrop = (option: boolean) => {
    setIsUploadingProfilePic(option);
    setCropModal(option);
  };

  const handleCloseImage = () => {
    setImageModal(false);
  };

  const handleCloseCropped = () => {
    setCroppedModal(false);
  };

  const truncateString = (text: string) => {
    if (text.length > 12) {
      return text.substring(0, 12) + "...";
    }
    return text;
  };

  return (
    <Box sx={{ m: 2 }}>
      <Box>
        {productData && (
          <>
            <Box display="flex" justifyContent={"space-between"}>
              <Typography
                variant="h6"
                style={{ fontSize: 30, fontWeight: 700 }}
              >
                Details of {productData.name}
              </Typography>

              <div style={{ marginLeft: "auto" }}>
                <IconButton
                  sx={{
                    "&:hover": {
                      background: theme.colors.primary.lighter,
                    },
                    color: theme.palette.primary.main,
                  }}
                  color="inherit"
                  size="small"
                  onClick={() => handleEditClick(productData)}
                >
                  <EditTwoTone fontSize="small" sx={{ color: "#0481D9" }} />
                </IconButton>

                <IconButton
                  sx={{
                    "&:hover": { background: theme.colors.error.lighter },
                    color: theme.palette.error.main,
                  }}
                  color="inherit"
                  size="small"
                  onClick={() => handleDeleteClick(productData)}
                >
                  <DeleteTwoTone fontSize="small" />
                </IconButton>
              </div>
            </Box>
            <Paper elevation={3} sx={{ padding: 1.5 }}>
              <Stack gap={1}>
                <Stack
                  gap={3}
                  mx={2}
                  mt={4}
                  direction="row"
                  justifyContent="space-between"
                >
                  <Stack
                    gap={3}
                    direction="row"
                    width="100%"
                    position="relative"
                  >
                    <Stack
                      style={{
                        zIndex: 1,
                      }}
                    >
                      <Paper
                        elevation={3}
                        sx={{
                          cursor: "pointer",
                          position: "relative",
                          marginBottom: "16px",
                          height: 200,
                          width: 200,
                        }}
                      >
                        {profilePicURL && (
                          <Box
                            onClick={() =>
                              handleOpenViewImage(
                                optimizeImageURL(profilePicURL, 512)
                              )
                            }
                          >
                            <img
                              src={optimizeImageURL(profilePicURL, 256)}
                              alt="Sponsor"
                              style={{
                                width: "200px",
                                height: "200px",
                                borderRadius: 8,
                                border: `8px solid ${theme.palette.background.default}`,
                                objectFit: "cover",
                              }}
                            />
                            <div
                              style={{
                                position: "absolute",
                                bottom: 8,
                                left: 0,
                                right: 0,
                                height: "64px",
                                background: `linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, ${theme.palette.background.default} 100%)`,
                              }}
                            />
                          </Box>
                        )}
                        <Box
                          sx={{
                            position: "absolute",
                            top: 14,
                            left: 14,
                          }}
                        >
                          <IconButton
                            component="label"
                            sx={{
                              background: theme.palette.primary.main,
                              color: "#fff",
                              "&:hover": {
                                backgroundColor: alpha(
                                  theme.palette.primary.main,
                                  0.5
                                ),
                              },
                              "&.Mui-disabled": {
                                backgroundColor: theme.palette.primary.main,
                              },
                            }}
                            color="inherit"
                            size="small"
                            disabled={isUploadingProfilePic}
                          >
                            {isUploadingProfilePic ? (
                              <CircularProgress
                                size={19}
                                sx={{ color: "#FFF", marginX: "2px" }}
                              />
                            ) : (
                              <PhotoCamera
                                fontSize="small"
                                sx={{ marginX: "2px" }}
                              />
                            )}
                            <input
                              id="profilePictureInput"
                              type="file"
                              accept="image/png, image/jpeg, image/webp"
                              {...register("profilePictureImage", {
                                onChange: (e) => handleFilePfp(e, "pfp"),
                              })}
                              hidden
                            />
                          </IconButton>
                        </Box>
                        <Typography
                          variant="h6"
                          style={{
                            fontSize: 16,
                            fontWeight: 700,
                            textAlign: "center",
                            position: "absolute",
                            bottom: 12,
                            left: 12,
                          }}
                        >
                          {truncateString(productData.name)}
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{
                            textAlign: "center",
                            position: "absolute",
                            bottom: 12,
                            right: 12,
                          }}
                        >
                          {getStatusLabel(productData.status) || "N/A"}
                        </Typography>
                      </Paper>
                    </Stack>
                    <Stack
                      style={{
                        position: "absolute",
                        top: -16,
                        left: 64,
                        width: "100%",
                        height: "100%",
                        zIndex: 0,
                      }}
                    >
                      <Paper
                        sx={{
                          marginBottom: "16px",
                          width: "93%",
                          height: "232px",
                          position: "relative",
                        }}
                      >
                        {coverURL ? (
                          <img
                            onClick={() =>
                              handleOpenViewImage(optimizeImageURL(coverURL))
                            }
                            src={optimizeImageURL(coverURL)}
                            alt="Profile cover"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              borderRadius: 8,
                              cursor: "pointer",
                            }}
                          />
                        ) : (
                          <Box>
                            <div
                              style={{
                                width: "100%",
                                height: "232px",
                                borderRadius: 8,
                                background: `linear-gradient(to bottom, ${theme.palette.background.default} 0%, #c5dae9 100%)`,
                              }}
                            ></div>
                          </Box>
                        )}
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: 10,
                            right: 10,
                          }}
                        >
                          <Button
                            component="label"
                            sx={{
                              background: theme.palette.primary.main,
                              color: "#fff",
                              "&:hover": {
                                backgroundColor: alpha(
                                  theme.palette.primary.main,
                                  0.5
                                ),
                              },
                            }}
                            color="inherit"
                            size="small"
                            disabled={isUploadingCover}
                          >
                            {isUploadingCover ? (
                              <CircularProgress
                                size={16}
                                sx={{ color: "#FFF", marginLeft: "4px" }}
                              />
                            ) : (
                              <PhotoCamera
                                fontSize="small"
                                style={{ marginLeft: 4 }}
                              />
                            )}
                            <Typography mx={1} color="#FFF">
                              {isUploadingCover
                                ? "Uploading..."
                                : `${coverURL ? "Edit" : "Upload"} cover photo`}
                            </Typography>
                            <input
                              id="profileCoverInput"
                              type="file"
                              accept="image/png, image/jpeg, image/webp"
                              {...register("profileCoverImage", {
                                onChange: (e) => handleFileCover(e, "cover"),
                              })}
                              hidden
                            />
                          </Button>
                        </Box>
                      </Paper>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack direction="row" mr={2} ml={2}>
                  <Grid container spacing={0}>
                    <Grid item xs={12} md={6}>
                      <Box sx={{ display: "flex", my: 1 }}>
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: 700, fontSize: 16, minWidth: 150 }}
                        >
                          Custom ID:
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: 16 }}>
                          {productData.custom_id}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box sx={{ display: "flex", my: 1 }}>
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: 700, fontSize: 16, minWidth: 150 }}
                        >
                          Total Posts:
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: 16 }}>
                          {productData?.totalPosts}
                        </Typography>
                      </Box>
                    </Grid>
                    {productData.basicInformation &&
                      Object.entries(productData.basicInformation).map(
                        ([label, value], index) => (
                          <Grid item xs={12} md={6} key={label}>
                            <Box sx={{ display: "flex", my: 1 }}>
                              <Typography
                                variant="body1"
                                sx={{
                                  fontWeight: 700,
                                  fontSize: 16,
                                  minWidth: 150,
                                }}
                              >
                                {label.charAt(0).toUpperCase() + label.slice(1)}
                                :
                              </Typography>
                              <Typography variant="body1" sx={{ fontSize: 16 }}>
                                {formatValue(value)}
                              </Typography>
                            </Box>
                          </Grid>
                        )
                      )}
                  </Grid>
                  <Stack justifyContent="center" width="50%">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Box
                        sx={{
                          borderRadius: 0.5,
                          border: "1px solid var(--font-400, #808080)",
                          px: 1,
                          pt: 1,
                          pb: 0.5,
                        }}
                        onClick={handleQRCodeClick}
                        style={{ cursor: "pointer" }}
                      >
                        <QRCode
                          id="qrCode"
                          value={productData.custom_id}
                          size={40}
                          level="Q"
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mt: 1,
                      }}
                    >
                      <Typography variant="body1">NFC Tag</Typography>
                      <IconButton onClick={handleInfoClick}>
                        <Info sx={{ color: "#999999" }} fontSize="small" />
                        <span style={{ fontSize: "small" }}>:</span>
                      </IconButton>
                      {productData.rfid_tag != null && (
                        <Box
                          sx={{ display: "flex", alignItems: "center", mr: 1 }}
                        >
                          <Typography
                            variant="h5"
                            sx={{
                              borderRadius: 1,
                              border: "1px solid var(--primary-300, #1EA2FF)",
                              color: "#FFFFFF",
                              background: "var(--primary-300, #1EA2FF)",
                              padding: "3px 6px",
                            }}
                          >
                            {productData.rfid_tag.length > 6
                              ? `${productData.rfid_tag.substring(
                                  0,
                                  3
                                )}...${productData.rfid_tag.substring(
                                  productData.rfid_tag.length - 3
                                )}`
                              : productData.rfid_tag}
                            <IconButton
                              onClick={() =>
                                copyToClipboard(
                                  productData.rfid_tag
                                    ? productData.rfid_tag
                                    : ""
                                )
                              }
                              size="small"
                            >
                              <ContentCopy
                                fontSize="small"
                                sx={{ color: "#FFFFFF" }}
                              />
                            </IconButton>
                          </Typography>
                        </Box>
                      )}
                      <Box
                        sx={{
                          borderRadius: 1,
                          border: "1px solid var(--primary-500, #0360A1);",
                        }}
                      >
                        <IconButton
                          sx={{
                            "&:hover": {
                              background: theme.colors.primary.lighter,
                            },
                            color: theme.palette.primary.main,
                          }}
                          color="inherit"
                          size="small"
                          onClick={handleRFID}
                        >
                          <EditTwoTone
                            fontSize="small"
                            sx={{ color: "#0481D9" }}
                          />
                          {productData.rfid_tag === null && (
                            <Typography>Add</Typography>
                          )}
                        </IconButton>
                      </Box>
                    </Box>
                  </Stack>
                </Stack>
              </Stack>
            </Paper>
            <Paper elevation={3} sx={{ padding: 1.5, mt: 2 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} ml={3}>
                  <DogGallery
                    key="crests-gallery"
                    title={"Crests"}
                    size={100}
                    limit={3}
                    type={DogGalleryType.CRESTS}
                    data={productData?.dogCrests}
                    dogId={productData._id}
                    refetch={refetch}
                  />
                  <DogGallery
                    key="photo-gallery"
                    title={"Gallery"}
                    size={180}
                    limit={6}
                    type={DogGalleryType.GALLERY}
                    data={productData?.dogAlbum}
                    dogId={productData._id}
                    refetch={refetch}
                  />
                  {productData.aboutMe && (
                    <>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "bold", my: 1, fontSize: 16 }}
                      >
                        About Me
                      </Typography>
                      {Object.entries(productData.aboutMe).map(
                        ([label, value], index) => (
                          <Box sx={{ my: 1, display: "flex" }} key={label}>
                            <Typography
                              variant="body1"
                              sx={{
                                fontWeight: 600,
                                fontSize: 14,
                                minWidth: 150,
                              }}
                            >
                              {label.charAt(0).toUpperCase() + label.slice(1)}:
                            </Typography>
                            <Typography variant="body1">
                              {formatValue(value)}
                            </Typography>
                          </Box>
                        )
                      )}
                    </>
                  )}
                  <Grid container spacing={2}>
                    {Object.values(productData.section).map(
                      (section: any, index) => (
                        <Grid item xs={12} key={index}>
                          <Typography
                            variant="h6"
                            sx={{ fontWeight: "bold", my: 1, fontSize: 16 }}
                          >
                            {section.section_name}
                          </Typography>
                          {Object.keys(section.section_details[0] || {})
                            .length > 0 ? (
                            section.section_details.map(
                              (details, detailsIndex) => (
                                <Grid container spacing={2} key={detailsIndex}>
                                  {section.section_details.map(
                                    (details, detailsIndex) => (
                                      <Fragment
                                        key={`${detailsIndex}-${details.title}`}
                                      >
                                        {Object.entries(details).map(
                                          ([key, value]) => (
                                            <Grid
                                              item
                                              xs={12}
                                              sm={6}
                                              md={6}
                                              key={`${key}-${detailsIndex}`}
                                            >
                                              <Box sx={{ display: "flex" }}>
                                                <Typography
                                                  variant="body1"
                                                  sx={{
                                                    fontWeight: 600,
                                                    fontSize: 14,
                                                    minWidth: 150,
                                                  }}
                                                >
                                                  {key.charAt(0).toUpperCase() +
                                                    key.slice(1)}
                                                  :
                                                </Typography>
                                                <Typography
                                                  variant="body1"
                                                  key={`${key}-${detailsIndex}`}
                                                >
                                                  {formatValue(value)}
                                                </Typography>
                                              </Box>
                                            </Grid>
                                          )
                                        )}
                                        {detailsIndex === 2 && (
                                          <Grid item xs={12} sm={6} md={6} />
                                        )}
                                      </Fragment>
                                    )
                                  )}
                                </Grid>
                              )
                            )
                          ) : (
                            <Typography
                              variant="body1"
                              key={`no-details-${index}`}
                            >
                              No details available
                            </Typography>
                          )}
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </>
        )}
      </Box>

      <ViewMediaDialog
        media={clickedImage}
        open={openImage}
        handleClose={handleCloseViewImage}
      />

      <DialogComponent
        open={openRfidg}
        width={496}
        height={286}
        handleClose={handleClose}
        content={
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <form onSubmit={handleSubmit(onSubmit)}>
                <InputLabel
                  sx={{ fontSize: 20, fontWeight: 700, color: "#1A1A1A" }}
                >
                  NFC Tag
                </InputLabel>
                <TextField
                  autoFocus
                  margin="dense"
                  id="rfidData"
                  type="text"
                  fullWidth
                  {...register("rfidData", { required: true })}
                  error={!!errors["rfidData"]}
                  helperText={
                    errors.rfidData
                      ? "NFC Tag is required"
                      : rfidError
                      ? "NFC Tag is required"
                      : ""
                  }
                />
                <DialogActions>
                  <Button onClick={handleClose} variant="outlined">
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained" autoFocus>
                    Save
                  </Button>
                </DialogActions>
              </form>
            </DialogContentText>
          </DialogContent>
        }
        actions={undefined}
      />

      <DialogComponent
        open={openDialog}
        width={720}
        handleClose={handleCloseDialog}
        content={
          <Box sx={{ maxHeight: "500px", overflowY: "auto" }}>
            <Typography
              variant="body1"
              sx={{ fontWeight: 700, fontSize: 18, textAlign: "center" }}
            >
              Instructions
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 700,
                fontSize: 18,
                marginBottom: "10px",
                mt: 1,
              }}
            >
              Steps
            </Typography>
            <ol style={{ listStyleType: "unset" }}>
              {instructions.map((step, index) => (
                <InstructionListItem key={index} text={step} />
              ))}
            </ol>

            <Typography
              variant="body1"
              sx={{
                fontWeight: 700,
                fontSize: 18,
                marginBottom: "10px",
                mt: 1,
              }}
            >
              Verification Steps
            </Typography>
            <ol style={{ listStyleType: "unset" }}>
              {verificationSteps.map((step, index) => (
                <InstructionListItem key={index} text={step} />
              ))}
            </ol>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 700,
                fontSize: 18,
                marginBottom: "10px",
                mt: 1,
              }}
            >
              Reference
            </Typography>
            <ol style={{ listStyleType: "unset" }}>
              <Typography
                variant="body1"
                sx={{ fontWeight: 500, fontSize: 16 }}
              >
                Here are some popular NFC tools mobile apps for Android and iOS:
              </Typography>
              <li>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: 500, fontSize: 16 }}
                >
                  Android:{" "}
                  <MuiLink
                    href="https://play.google.com/store/apps/details?id=com.winningapps.nfctagreader"
                    target="_blank"
                    rel="noopener"
                  >
                    https://play.google.com/store/apps/details?id=com.winningapps.nfctagreader
                  </MuiLink>
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: 500, fontSize: 16 }}
                >
                  iOS:{" "}
                  <MuiLink
                    href="https://apps.apple.com/us/app/nfc-tools/id1252962749"
                    target="_blank"
                    rel="noopener"
                  >
                    https://apps.apple.com/us/app/nfc-tools/id1252962749
                  </MuiLink>
                </Typography>
              </li>
            </ol>
          </Box>
        }
        actions={undefined}
      />
      <DialogComponent
        open={openDelete}
        width={324}
        height={240}
        handleClose={handleClose}
        content={
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this Dog?
            </DialogContentText>
          </DialogContent>
        }
        actions={
          <DialogActions>
            <Button onClick={handleClose}>No</Button>
            <Button onClick={handleDelete} autoFocus>
              Yes
            </Button>
          </DialogActions>
        }
      />

      {profilePicSrc && openCropModal && (
        <CropModal
          src={profilePicSrc}
          setCroppedImageUrl={setCroppedImageUrlCallback}
          openCropModal={openCropModal}
          setCropModal={setCropModal}
          setLoadingImage={setIsProfilePicSrcLoading}
          onClose={handleCloseCrop}
        />
      )}

      {imageModal && (
        <DialogComponent
          open={imageModal}
          width={324}
          height={240}
          handleClose={handleCloseImage}
          content={
            <Box display="flex" flexDirection="column" alignItems="center">
              <ErrorOutline color="error" sx={{ fontSize: 72, mb: 4 }} />
              <DialogContentText
                id="alert-dialog-description"
                sx={{ color: "black" }}
              >
                <strong>Please choose an image larger than 300x300</strong>
              </DialogContentText>
            </Box>
          }
          actions={undefined}
        />
      )}

      {(profilePicURL && !isUploadingProfilePic) && (
        <DialogComponent
          open={openCroppedModal}
          width={324}
          height={240}
          handleClose={handleCloseCropped}
          content={
            <Box display="flex" flexDirection="column" alignItems="center">
              <CheckCircle color="success" sx={{ fontSize: 72, mb: 4 }} />
              <DialogContentText
                id="alert-dialog-description"
                sx={{ color: "black" }}
              >
                <strong>Image cropped successfully</strong>
              </DialogContentText>
            </Box>
          }
          actions={undefined}
        />
      )}
    </Box>
  );
};
export default DogDetails;
