// web-react-admin-lavabee\src\modules\videos\VideoEditor\components\CustomTooltip.tsx
import React from 'react';
import styles from '../styles/VideoEditor.module.scss';

interface CustomTooltipProps {
  children: React.ReactNode;
  title: React.ReactNode;
}

export const CustomTooltip: React.FC<CustomTooltipProps> = ({ children, title }) => (
  <div className={styles.tooltipContainer}>
    {children}
    <span className={styles.tooltip}>{title}</span>
  </div>
);