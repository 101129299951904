import {Card, CardMedia, CardContent, Typography, Box} from "@mui/material";
import moment from "moment-timezone";
import { parseVideoPresets } from "../../shared/utilities/helpers";

type Props = {
  image: string;
  onClick: () => void;
  createdAt?: string;
  thumbnail_url?: string;
  dog_name?: string;
  location?: string;
  time?: string;
  rfid?: string;
  video_presets?: string;
  event: string;
};

function parseDate(time?: string) {
  let date = moment.utc(time, "YYYY-MM-DD_HH_mm_ss");

  if (!date.isValid()) {
    date = moment.utc(time);
  }

  return date;
}

function PreviewVideo(props: Props) {
  const formattedDateUTC = parseDate(props?.time).format(
    "MMM Do YYYY, h:mm:ss a"
  );
  const formattedDateDallas = parseDate(props?.time)
    .tz("America/Chicago")
    .format("MMM Do YYYY, h:mm:ss a");

  const videoPresets = parseVideoPresets(props.video_presets);
  const rotation = videoPresets?.rotation || 0;

  return (
    <Card
      elevation={3}
      onClick={props.onClick}
      style={{cursor: "pointer", borderRadius: "15px", transition: "0.2s"}}
    >
      <Box
        sx={{
          width: '100%',
          paddingTop: rotation === 90 || rotation === 270 ? '80%' : '56.25%', // 16:9 aspect ratio
          position: 'relative',
          overflow: 'hidden',
          borderRadius: "15px 15px 0 0",
        }}
      >
        <CardMedia
          component="img"
          alt="Video preview"
          image={
            props?.thumbnail_url ||
            "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/310px-Placeholder_view_vector.svg.png"
          }
          crossOrigin="anonymous"
          title="Video preview"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            transform: `rotate(${rotation}deg)`,
            transformOrigin: 'center',
            objectFit: 'cover',
          }}
        />
      </Box>
      <CardContent>
        <Typography variant="subtitle1" component="p">
          DOG Name: {props?.dog_name}
        </Typography>

        <Typography variant="subtitle1" component="p">
          DOG Location: {props?.location}
        </Typography>
        <Typography variant="subtitle1" component="p">
          RFID: {props?.rfid ? props?.rfid : "No RFID"}
        </Typography>

        <Typography variant="body2" color="textSecondary" component="p">
          UTC : {formattedDateUTC}
        </Typography>

        <Typography variant="body2" color="textSecondary" component="p">
          Dallas : {formattedDateDallas}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          Event : {props?.event}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default PreviewVideo;