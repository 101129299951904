import Hls from "hls.js";
import {useEffect, useRef, useState} from "react";

export default function HlsVideoPlayer({src, ...props}) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const manifestLoadedRef = useRef(false);
  const [loadingIntro, setLoadingIntro] = useState(false);

  useEffect(() => {
    let hls = null;
    let intervalId = null;

    const reloadManifest = () => {
      if (!manifestLoadedRef.current && hls) {
        hls.loadSource(src);
      }
    };

    const initPlayer = () => {
      hls = new Hls({debug: false});

      if (Hls.isSupported()) {
        if (videoRef.current) {
          hls.attachMedia(videoRef.current);
        }

        hls.on(Hls.Events.MANIFEST_PARSED, (_, data) => {
          if (data.levels.length > 0) {
            const playlist = data.levels[0].details;
            if (
              playlist.live &&
              playlist.fragments.length === 1 &&
              playlist.fragments[0].tagList.some((tag) =>
                tag.includes("EXTINF:6,")
              )
            ) {
              setLoadingIntro(true);
              manifestLoadedRef.current = false;
              clearInterval(intervalId);
              intervalId = setInterval(() => {
                if (hls) hls.destroy();
                initPlayer();
              }, 4500);
            } else {
              setLoadingIntro(false);
              manifestLoadedRef.current = true;
              clearInterval(intervalId);
              if (videoRef.current) {
                videoRef.current.currentTime = 0;
              }
            }
          }
        });

        hls.on(Hls.Events.FRAG_CHANGED, (_, data) => {
          const currentFragment = data.frag;
          if (
            currentFragment &&
            !currentFragment.relurl.includes("intro") &&
            currentFragment.sn > 0 &&
            !manifestLoadedRef.current
          ) {
            hls.loadSource(src);
            hls.once(Hls.Events.MANIFEST_PARSED, () => {
              if (videoRef.current) {
                videoRef.current.currentTime = 0;
                videoRef.current.play();
              }
            });
          }
        });

        hls.on(Hls.Events.ERROR, (_, data) => {
          if (data.fatal) {
            switch (data.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                hls.startLoad();
                break;
              case Hls.ErrorTypes.MEDIA_ERROR:
                hls.recoverMediaError();
                break;
              default:
                hls.destroy();
                break;
            }
          }
        });

        reloadManifest();
      } else {
        // console.log("HLS not supported");
      }
    };

    initPlayer();

    return () => {
      if (hls) {
        hls.destroy();
      }
      clearInterval(intervalId);
    };
  }, [src]);

  return (
    <>
      {loadingIntro && <div>Loading video...</div>}
      <video
        ref={videoRef}
        controls
        autoPlay
        playsInline
        loop={true}
        {...props}
        style={{
          width: 768,
          height: 432,
          display: loadingIntro ? "none" : "block"
        }}
      />
    </>
  );
}
