const toTimeString = (sec: string | number, showMilliSeconds = true) => {
  const totalSeconds = parseFloat(sec);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);
  const milliseconds = Math.round((totalSeconds % 1) * 1000);

  const padZero = (num: number) => num.toString().padStart(2, '0');

  let timeString = `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;

  if (showMilliSeconds) {
    timeString += `.${milliseconds.toString().padStart(3, '0')}`;
  }

  return timeString;
};

const download = (url: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "");
  link.click();
};

/**
 * Optimizes an image URL by replacing the S3 URL with a CloudFront URL and appending a query parameter to specify the image width. This function is designed to work with AWS services, where an AWS Lambda function can be used to dynamically optimize images via CloudFront.
 *
 * @param {string} url - The original image URL hosted on AWS S3.
 * @param {number} [size=1024] - The desired width of the optimized image. Defaults to 1024 pixels.
 * @returns {string} The optimized image URL with the CloudFront domain and a query parameter specifying the image width.
 */
const optimizeImageURL = (url: string, size: number = 1024) => {
  const wdfs3URL = process.env.WDFS3URL;
  const cloudFrontURL = process.env.CLOUDFRONT_URL;

  url = url.replace(wdfs3URL, cloudFrontURL);
  const optimizedURL = `${url}?width=${size}`;

  return optimizedURL;
};

const resolutionsMap = {
  "360": { width: "640", height: "360" },
  "432": { width: "768", height: "432" },
  "720": { width: "1280", height: "720" },
  "1080": { width: "1920", height: "1080" },
};

/**
 * Optimizes a video URL by replacing the original URL with a CloudFront URL and appending query parameters to specify the video resolution and dimensions. This function is designed to work with a specific CloudFront distribution and video streaming setup.
 *
 * @param {string} url - The original video URL.
 * @param {keyof typeof resolutionsMap} [preset="432"] - The desired resolution preset for the optimized video. Defaults to "432" (768x432).
 * @returns {string} The optimized video URL with the CloudFront domain, streaming file name, and query parameters specifying the video dimensions and original file name.
 */
const optimizeVideoURL = (
  url: string,
  preset: keyof typeof resolutionsMap = "432"
) => {
  const cloudFrontURL = process.env.CLOUDFRONT_URL;
  const regex =
    /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/i;

  const { width, height } = resolutionsMap[preset];

  const match = url.match(regex);
  if (match) {
    const path = match[2];
    const splitPath = path.split("/");
    const fileName = splitPath.pop();
    const mediaFilePath = splitPath.join("/");
    const fileExtension = fileName.split(".").pop();
    const baseFileName = fileName.slice(0, -(fileExtension.length + 1));
    const streamingFile = `${baseFileName}-${width}x${height}.m3u8`;
    const optimizedURL = `${cloudFrontURL}/${mediaFilePath}/${streamingFile}?width=${width}&height=${height}&mediafilename=${path}`;
    return optimizedURL;
  }

  return url;
};


/**
 * Determines the transpose value for video rotation based on the given rotation angle.
 *
 * @param {number} rotation - The rotation angle in degrees (90, 180, or 270).
 * @returns {string} The transpose value to be used in FFmpeg video filters.
 */
const getTransposeValue = (rotation: number): string => {
  switch (rotation) {
  case 90: return "1"; // Rotate 90 degrees clockwise
  case 180: return "2,2"; // Rotate 180 degrees
  case 270: return "2"; // Rotate 90 degrees counterclockwise
  default: return "1";
  }
};

/**
 * Downloads a blob as a file using the provided data URL and file name.
 *
 * @param {string} dataURL - The data URL representing the blob content.
 * @param {string} fileName - The desired name for the downloaded file.
 */
const downloadBlob = (dataURL: string, fileName: string): Promise<void> => {
  return new Promise((resolve) => {
    const link = document.createElement('a');
    link.href = dataURL;
    link.download = fileName;
    link.click();
    setTimeout(() => {
      URL.revokeObjectURL(dataURL);
      resolve();
    }, 100);
  });
};

/**
 * Parses the video presets from a string to an object.
 *
 * @param {string} presets - The video presets string.
 * @returns {Object} The parsed video presets object.
 */
export function parseVideoPresets(presets: string | undefined):
  {
    trimStart?: number;
    trimEnd?: number;
    rotation?: number;
  }
  | null {

  if (!presets) return null;

  try {
    const parsedPresets = JSON.parse(presets);
    return {
      trimStart: parsedPresets.trimStart,
      trimEnd: parsedPresets.trimEnd,
      rotation: parsedPresets.rotation
    };
  } catch (error) {
    console.error("Error parsing video presets:", error);
    return null;
  }
}

export { toTimeString, download, optimizeImageURL, optimizeVideoURL, getTransposeValue, downloadBlob };
