import React, {useEffect, useState} from "react";
import {
  Box,
  Button,
  // CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
// import Autocomplete from "@mui/material/Autocomplete";
import {useLazyQuery, useMutation} from "@apollo/client";
import {CREATE_ADMIN_POST} from "../../shared/graphQL/post/queries";
import {GENERATE_PRESIGNED_URL} from "../../shared/graphQL/common/queries";
import LoadingButton from "@mui/lab/LoadingButton";
import {GET_PRODUCTS} from "../../shared/graphQL/dog/queries";
import {debounce} from "@mui/material/utils";

const thumbnailDummy =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSPj0v1gcW162kgJHrRHpC-PidD-c5Ejyg099PZLQq_qF37jHBPB7Ihyq_Dndp9atLFRKU&usqp=CAU";

interface Props {
  isVisible: boolean;
  onClose: () => void;
  videoSrc: string;
  onSuccessPost: () => void;
  dogId?: string;
  selectedDogName?: string;
  thumbnail_url?: string;
}

function PostVideoModal({
  isVisible,
  onClose,
  videoSrc,
  onSuccessPost,
  dogId,
  selectedDogName,
  thumbnail_url
}: Props) {
  const [dogsId, setDogId] = useState("");
  const [dogName, setDogName] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState("");
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (dogId?.length > 0) {
      setDogId(dogId);
    }

    if (selectedDogName?.length > 0) {
      setDogName(selectedDogName);
    }
  }, [dogId, selectedDogName]);

  const [generatePresignedUrlAWS, {loading: s3PresingedUrlLoading}] =
    useMutation(GENERATE_PRESIGNED_URL);

  const wdfs3Url = process.env.WDFS3URL;
  const wdfs3oldUrl = process.env.WDFOLDS3URL;

  const [createAdminPost, {data, loading}] = useMutation(CREATE_ADMIN_POST);

  async function fetchDataUrlContent(
    dataUrl,
    fileName = "video.mp4",
    fileType = "video/mp4"
  ) {
    const response: any = await fetch(dataUrl);
    const blob = await response?.blob();
    const file = new File([blob], fileName, {type: fileType});
    return file;
  }

  const uploadImage = async (url, data) => {
    setUploading(true);

    await fetch(url, {
      method: "PUT",
      body: data,
      headers: {
        "Content-Type": data.type
      }
    });
    setUploading(false);
  };

  const onApprove = async () => {
    if (!dogsId) {
      setError("Please select dog");
    } else {
      const file = await fetchDataUrlContent(videoSrc);

      const fileTypeTemp = file?.type?.split("/");
      const fileType = fileTypeTemp[fileTypeTemp?.length - 1] || "mp4";

      const payload = {
        fileName: `${new Date().toISOString()}.${fileType}`,
        fileType: file.type,
        filePath: "product"
      };

      const s3Url = await generatePresignedUrlAWS({
        variables: {input: payload}
      });

      await uploadImage(s3Url?.data.GeneratePresignedUrl.presignedUrl, file);

      const accessUrls =
        s3Url?.data.GeneratePresignedUrl.presignedUrl.split("?");

      const updatedUrl = accessUrls?.[0]
        ? accessUrls?.[0].replace(wdfs3oldUrl, wdfs3Url)
        : "";

      const currentDate = new Date();

      await createAdminPost({
        variables: {
          input: {
            caption: description,
            postGallery: [
              {
                url: updatedUrl,
                mime_type: "video/mp4",
                thumbnail_url: thumbnail_url || thumbnailDummy
              }
            ],
            productId: dogsId,
            sessionStart: currentDate.toISOString(),
            sessionEnd: new Date(
              currentDate.getTime() + 15 * 60 * 1000
            ).toISOString()
          }
        }
      });

      onSuccessPost();
    }
  };

  return (
    <Dialog
      onClose={onClose}
      open={isVisible}
      PaperProps={{
        style: {
          maxWidth: "100%"
        }
      }}
    >
      <DialogTitle id="form-dialog-title">
        <Box
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography sx={{fontSize: "20px", fontWeight: 700}}>
            Post Video
          </Typography>
          <IconButton
            edge="end"
            color="primary"
            onClick={onClose}
            aria-label="close"
          >
            <CancelIcon sx={{fontSize: 30, color: "#0481D9"}} />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent style={{marginTop: 4}}>
        <Box display={"flex"}>
          <video
            width={400}
            src={videoSrc}
            controls
            style={{borderRadius: 5}}
          />

          <Box ml={4} width={300} mt={1}>
            <TextField
              fullWidth
              id="dog_name"
              value={dogName}
              label="Dog Name"
              type="text"
              disabled
            />
            <TextField
              margin="dense"
              id="description"
              value={description}
              label="Description"
              type="text"
              onChange={(e) => {
                console.log("e", e.target.value);
                setDescription(e.target.value);
              }}
              fullWidth
              multiline
              rows={3}
            />

            {error.length > 0 && (
              <Typography
                sx={{fontSize: "15px", fontWeight: 700, color: "red"}}
              >
                {error}*
              </Typography>
            )}

            <DialogActions style={{marginTop: 5}}>
              <Box display="flex" justifyContent="space-between" width="100%">
                <Button
                  variant="outlined"
                  color="error"
                  sx={{mr: 1}}
                  onClick={onClose}
                >
                  Cancel
                </Button>

                <LoadingButton
                  variant="contained"
                  onClick={onApprove}
                  color="success"
                  loading={s3PresingedUrlLoading || loading || uploading}
                >
                  Create post
                </LoadingButton>
              </Box>
            </DialogActions>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default PostVideoModal;
