import React, { useEffect } from "react";
import {
  Stack,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { REMOVE_VIDEO } from "../../shared/graphQL/video";

interface Props {
  isVisible: boolean;
  onClose: () => void;
  videoId: string;
  onSuccess: () => void;
}

function DeleteVideoModal({ isVisible, onClose, videoId, onSuccess }: Props) {
  const [removeVideo, { data: removeVideoData }] =
    useMutation(REMOVE_VIDEO);

  const handleDelete = () => {
    removeVideo({ variables: { input: { _id: videoId } } });
  };

  useEffect(() => {
    if (removeVideoData) {
      onSuccess();
    }
  }, [removeVideoData]);

  return (
    <Dialog
      onClose={onClose}
      open={isVisible}
      PaperProps={{
        style: {
          maxWidth: "100%",
        },
      }}
    >
      <DialogTitle id="form-dialog-title">
        <Box display="flex" justifyContent={"center"} alignItems={"center"}>
          <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>
            Delete Video
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent style={{ marginTop: 4 }}>
        <Stack gap={2}>
          <Box
            display="flex"
            justifyContent={"space-between"}
            alignItems={"center"}
            width={256}
          >
            <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
              Are you sure you want to permanently delete the video?
            </Typography>
          </Box>
          <Stack gap={2} direction="row" justifyContent="center">
            <Button variant="outlined" color="primary" onClick={onClose}>
              No
            </Button>
            <Button variant="contained" color="primary" onClick={handleDelete}>
              Yes
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default DeleteVideoModal;
