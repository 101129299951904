// web-react-admin-lavabee\src\modules\videos\EditVideo\hooks\useModalStates.ts
import { useState } from 'react';

export const useModalStates = () => {
  const [isPostModalVisible, setPostModalVisible] = useState(false);
  const [postSubmittedSuccess, setPostSubmittedSuccess] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteVideoSuccess, setDeleteVideoSuccess] = useState(false);
  const [saveEditSuccess, setSaveEditSuccess] = useState(false);
  const [saveEditError, setSaveEditError] = useState(false);

  return {
    isPostModalVisible,
    setPostModalVisible,
    postSubmittedSuccess,
    setPostSubmittedSuccess,
    openDelete,
    setOpenDelete,
    deleteVideoSuccess,
    setDeleteVideoSuccess,
    saveEditSuccess,
    setSaveEditSuccess,
    saveEditError,
    setSaveEditError
  };
};