import {DateRangePicker} from "react-date-range";
import DialogComponent from "../../shared/components/Dialog";
import {Button} from "@mui/material";
import {Box} from "@mui/system";
import {useState} from "react";

import "./pickerStyles.css";
import "./pickerTheme.css";

interface Props {
  initialSelectionRange: any;
  open: boolean;
  handleClose?: () => void;
  handleDateRangeChange: (ranges: any) => void;
}

function DatePickerModal({
  initialSelectionRange: initialSelectionRange,
  open,
  handleClose,
  handleDateRangeChange
}: Props) {
  const [selectionRange, setSelectionRange] = useState<any>({
    startDate: initialSelectionRange[0] || new Date(),
    endDate: initialSelectionRange[1] || new Date(),
    key: "selection"
  });

  const handleDateRange = (ranges: any) => {
    setSelectionRange(ranges.selection);
  };
  const handleApply = () => {
    handleDateRangeChange([selectionRange.startDate, selectionRange.endDate]);
  };

  return (
    <div>
      <DialogComponent
        open={open}
        content={
          <DateRangePicker
            direction="vertical"
            showSelectionPreview
            maxDate={new Date()}
            focusedRange={[0, 1]}
            ranges={[selectionRange]}
            onChange={handleDateRange}
          />
        }
        actions={
          <Box columnGap={2}>
            <Button
              variant="text"
              onClick={handleClose}
              style={{marginRight: 20}}
            >
              Clear
            </Button>

            <Button variant="contained" color="primary" onClick={handleApply}>
              Apply
            </Button>
          </Box>
        }
      />
    </div>
  );
}

export default DatePickerModal;
