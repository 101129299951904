import React, { useState, useRef, useEffect } from "react";
import ReactCrop, {
  centerCrop,
  Crop,
  makeAspectCrop,
  PixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";
import CropIcon from "@mui/icons-material/Crop";
import InfoIcon from "@mui/icons-material/Info";
import RefreshIcon from "@mui/icons-material/Refresh";

import { GENERATE_PRESIGNED_URL } from "../../graphQL/common/queries";
import { useMutation } from "@apollo/client";

const tooltipInfo =
  "The cropped image will always be a perfect square (1:1 aspect ratio). Drag the corner handles to resize the crop area. Click and drag inside the crop area to reposition it.";

interface CropModalProps {
  openCropModal: boolean;
  setCropModal: (open: boolean) => void;
  setCroppedImageUrl: any;
  src: string;
  setLoadingImage: (open: boolean) => void;
  onClose?: (option: boolean) => void;
}

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const CropModal: React.FC<CropModalProps> = ({
  openCropModal,
  setCropModal,
  src,
  setCroppedImageUrl,
  setLoadingImage,
  onClose,
}) => {
  const theme = useTheme();
  const [crop, setCrop] = useState<Crop>();
  const [aspect, setAspect] = useState<number | undefined>(1 / 1);
  const [initialCrop, setInitialCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const imgRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [generatePresignedUrlAWS, { data: createPresignedUrl }] = useMutation(
    GENERATE_PRESIGNED_URL
  );
  const wdfs3Url = process.env.WDFS3URL;
  const wdfs3oldUrl = process.env.WDFOLDS3URL;

  useEffect(() => {
    if (completedCrop && imgRef.current && previewCanvasRef.current) {
      const image = imgRef.current;
      const canvas = previewCanvasRef.current;
      const ctx = canvas?.getContext("2d");

      if (!ctx) {
        return;
      }

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const pixelRatio = window.devicePixelRatio || 1;

      canvas.width = Math.floor(completedCrop.width * scaleX * pixelRatio);
      canvas.height = Math.floor(completedCrop.height * scaleY * pixelRatio);

      ctx.scale(pixelRatio, pixelRatio);
      ctx.imageSmoothingQuality = "high";

      ctx.drawImage(
        image,
        completedCrop.x * scaleX,
        completedCrop.y * scaleY,
        completedCrop.width * scaleX,
        completedCrop.height * scaleY,
        0,
        0,
        completedCrop.width * scaleX,
        completedCrop.height * scaleY
      );

      // const croppedImageUrl = canvas.toDataURL("image/jpeg");
      // setCroppedImageUrl(croppedImageUrl);
    }
  }, [completedCrop]);

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, aspect));
    setInitialCrop(centerAspectCrop(width, height, aspect));
  }

  const handleDone = async () => {
    setCropModal(false);
    setLoadingImage(true);
    const canvas = await previewCanvasRef.current;
    const base64ImageData = canvas?.toDataURL("image/jpeg");
    const croppedImageFile = dataURLtoFile(
      base64ImageData,
      "cropped_image.jpg"
    );

    let size = croppedImageFile.size;
    const payload = {
      fileName: "cropped_image.jpg",
      fileType: croppedImageFile.type,
      filePath: "sponsor",
    };

    const presignedUrl = await generatePresignedUrlAWS({
      variables: { input: payload },
    });
    await uploadImage(
      presignedUrl?.data.GeneratePresignedUrl.presignedUrl,
      croppedImageFile
    );

    const updatedUrl =
      presignedUrl?.data.GeneratePresignedUrl.presignedUrl.split("?")[0]
        ? presignedUrl?.data.GeneratePresignedUrl.presignedUrl
            .split("?")[0]
            .replace(wdfs3oldUrl, wdfs3Url)
        : "";
        
    setCroppedImageUrl(updatedUrl);
    setLoadingImage(false);
    setCompletedCrop(null);
  };

  const percentCropToPixelCrop = (
    percentCrop,
    width: number,
    height: number
  ): PixelCrop => {
    return {
      unit: "px",
      x: Math.round((percentCrop.x * width) / 100),
      y: Math.round((percentCrop.y * height) / 100),
      width: Math.round((percentCrop.width * width) / 100),
      height: Math.round((percentCrop.height * height) / 100),
    };
  };

  const handleResetCrop = () => {
    if (initialCrop) {
      setCrop(initialCrop);
      setCompletedCrop(
        percentCropToPixelCrop(
          initialCrop,
          imgRef.current.width,
          imgRef.current.height
        )
      );
    }
  };

  const handleCloseCrop = () => {
    if (onClose) {
      onClose(false);
    } else {
      setCropModal(false);
    }
    setCrop(null);
    setCompletedCrop(null);
  };

  const uploadImage = async (url, data) => {
    await fetch(url, {
      method: "PUT",
      body: data,
      headers: {
        "Content-Type": data.type,
      },
    });
  };

  const dataURLtoFile = (dataURL, fileName) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
  };

  return (
    <>
      <Dialog
        open={openCropModal}
        onClose={handleCloseCrop}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            maxWidth: "100%",
            maxHeight: "100%",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Box
            display="flex"
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>
              Crop Image
            </Typography>
            <Stack direction="row" gap={1} alignItems="center">
              <Tooltip title={tooltipInfo}>
                <InfoIcon sx={{ fontSize: 30, color: "#0481D9" }} />
              </Tooltip>
              <IconButton
                component="label"
                size="small"
                color="primary"
                onClick={handleCloseCrop}
                aria-label="close"
              >
                <CancelIcon
                  sx={{ fontSize: 30, color: theme.palette.error.main }}
                />
              </IconButton>
            </Stack>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box height="100%">
            <Stack
              gap={2}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                {!!src && (
                  <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(completedCrop) => {
                      setCompletedCrop(completedCrop);
                    }}
                    aspect={aspect}
                  >
                    <img
                      ref={imgRef}
                      alt="Crop me"
                      src={src}
                      onLoad={onImageLoad}
                      style={{ maxWidth: '100%', maxHeight: '100%' }}
                    />
                  </ReactCrop>
                )}
                <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                  Drag the handles to adjust the crop area
                </Typography>
              </Box>
              <Box>
                {!!completedCrop && (
                  <Stack gap={2} alignItems="center">
                    <canvas
                      ref={previewCanvasRef}
                      style={{
                        border: "1px solid black",
                        objectFit: "contain",
                        width: completedCrop.width,
                        height: completedCrop.height,
                        maxWidth: 300,
                        maxHeight: 300,
                      }}
                    />
                    <Stack gap={1} alignItems="center">
                      <Stack alignItems="center">
                        <Typography sx={{ fontSize: "10px", fontWeight: 500 }}>
                          Current size
                        </Typography>
                        <Typography
                          sx={{ fontSize: "18px", fontWeight: 500 }}
                        >{`${completedCrop.width.toFixed(
                          0
                        )} x ${completedCrop.height.toFixed(
                          0
                        )} px`}</Typography>
                      </Stack>
                      {completedCrop.width < 300 && (
                        <Alert severity="error">
                          Crop area must be larger than 300x300
                        </Alert>
                      )}
                    </Stack>
                  </Stack>
                )}
              </Box>
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            justifyContent="space-between"
            width="100%"
            m={1}
          >
            <Button
              startIcon={<CancelIcon />}
              onClick={handleCloseCrop}
              variant="contained"
              color="error"
            >
              Cancel
            </Button>
            <Stack direction="row" gap={2}>
              <Button
                startIcon={<RefreshIcon />}
                onClick={handleResetCrop}
                variant="contained"
                color="primary"
                disabled={crop === initialCrop}
                autoFocus
              >
                Reset Crop
              </Button>
              <Button
                startIcon={<CropIcon />}
                onClick={handleDone}
                variant="contained"
                color="success"
                autoFocus
                disabled={completedCrop?.width < 300}
              >
                Apply Crop
              </Button>
            </Stack>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CropModal;
