// web-react-admin-lavabee\src\shared\components\ImageUpload\index.styles.tsx
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const ImageUploadBox = styled(Box)(({ theme }) => ({
  border: `2px dashed ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  textAlign: 'center',
  minHeight: '300px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  'label': {
    cursor: 'pointer',
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

export const CropContainer = styled(Box)({
  position: 'relative',
  width: '100%',
  height: '250px',
  marginBottom: '20px',
});

export const Controls = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  'button': {
    padding: 0,
  }
}));

export const OutputContainer = styled(Box)({
  position: 'relative',
  width: '100%',
  overflow: 'hidden',
  '& > img': {
    position: 'absolute',
    top: 0,
    left: 0,
    transformOrigin: 'top left',
  },
});
