// web-react-admin-lavabee\src\modules\videos\VideoEditor\components\VideoCanvas.tsx
import React, { useRef, useEffect, useCallback, useState } from 'react';
import styles from '../styles/VideoEditor.module.scss';
import { observer } from "mobx-react-lite";
import { mainStore } from '../main';

interface VideoCanvasProps {
  video: HTMLVideoElement;
  onClick: () => void;
  onTimeUpdate: (currentTime: number, progress: number) => void;
  ignoreTimeUpdatesRef: React.RefObject<boolean>;
}

export const VideoCanvas: React.FC<VideoCanvasProps> = observer(({ video, onClick, onTimeUpdate, ignoreTimeUpdatesRef }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isVideoReady, setIsVideoReady] = useState(false);

  const renderFrame = useCallback(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');

    if (canvas && context && video.readyState >= 2) {
      const { videoWidth, videoHeight } = video;
      const isPortrait = mainStore.transform.rotation % 180 !== 0;

      canvas.width = isPortrait ? videoHeight : videoWidth;
      canvas.height = isPortrait ? videoWidth : videoHeight;

      context.save();
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.translate(canvas.width / 2, canvas.height / 2);
      context.rotate((mainStore.transform.rotation * Math.PI) / 180);
      context.drawImage(
        video,
        -videoWidth / 2,
        -videoHeight / 2,
        videoWidth,
        videoHeight
      );
      context.restore();

      if (!ignoreTimeUpdatesRef.current) {
        onTimeUpdate(video.currentTime, (video.currentTime / video.duration) * 100);
      }
    }
  }, [video, onTimeUpdate, ignoreTimeUpdatesRef]);

  useEffect(() => {
    let animationFrameId: number;

    const animate = () => {
      renderFrame();
      if (isPlaying) {
        animationFrameId = requestAnimationFrame(animate);
      }
    };

    if (isPlaying) {
      animationFrameId = requestAnimationFrame(animate);
    } else if (isVideoReady) {
      renderFrame(); // Render once when paused to update the frame
    }

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [renderFrame, isPlaying, isVideoReady]);

  useEffect(() => {
    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);
    const handleCanPlay = () => {
      setIsVideoReady(true);
      renderFrame();
    };

    video.addEventListener('play', handlePlay);
    video.addEventListener('pause', handlePause);
    video.addEventListener('canplay', handleCanPlay);

    return () => {
      video.removeEventListener('play', handlePlay);
      video.removeEventListener('pause', handlePause);
      video.removeEventListener('canplay', handleCanPlay);
    };
  }, [video, renderFrame]);

  useEffect(() => {
    if (isVideoReady) {
      renderFrame();
    }
  }, [mainStore.transform.rotation, renderFrame, isVideoReady]);

  useEffect(() => {
    if (isVideoReady) {
      renderFrame(); // Render once when rotation changes
    }
  }, [mainStore.transform.rotation, renderFrame, isVideoReady]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.style.width = '100%';
      canvas.style.height = '100%';
      canvas.style.objectFit = 'contain';
    }
  }, [mainStore.transform.rotation]);

  return (
    <div className={`${styles.videoContainer} ${mainStore.transform.rotation % 180 !== 0 ? styles.portrait : ''}`}>
      <canvas
        ref={canvasRef}
        className={styles.videoPreview}
        onClick={onClick}
      />
    </div>
  );
});