// web-react-admin-lavabee\src\modules\videos\EditVideo\hooks\useVideoActions.ts
import {useState, useCallback, useRef} from "react";
import {mainStore} from "../../VideoEditor/main";
import {
  toTimeString,
  getTransposeValue,
  downloadBlob
} from "../../../../shared/utilities/helpers";
import moment from "moment";
import {runInAction} from "mobx";

// Helper flag to log debug information
const DEBUG_LOGS = false;

export const useVideoActions = (
  videoRotation: number,
  setProcessProgress: (progress: number) => void,
  isDogActive: boolean,
  showDogInactiveError: () => string,
  setPostModalVisible: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const [postStatus, setPostStatus] = useState(false);
  const [downloadStatus, setDownloadStatus] = useState(false);
  const [postVideoUrl, setPostVideoUrl] = useState<string | null>(null);
  const cancelRef = useRef<AbortController | null>(null);

  const processVideo = useCallback(
    async (isDownload: boolean, isDogActive: boolean) => {
      const startTime = performance.now();
      const isPost = !isDownload;

      if (!isDogActive && isPost) {
        showDogInactiveError();
        return;
      }

      const actionStatus = isDownload ? setDownloadStatus : setPostStatus;
      actionStatus(true);

      const ffmpeg = mainStore.ffmpeg;
      const {video, transform, file} = mainStore;

      const start = transform.time?.[0] || 0.0;
      const end = transform.time?.[1] || video.duration;
      const trimDuration = end - start;

      const startTimeStr = toTimeString(start, true);
      const durationTimeStr = toTimeString(trimDuration, true);

      const progressListener = (progress: number) => {
        setProcessProgress(Math.round(progress * 100));
      };

      let outputWidth = "1280";
      let outputHeight = "720";

      if (videoRotation === 90 || videoRotation === 270) {
        // Changes the width and height if the video is vertical
        [outputWidth, outputHeight] = [outputHeight, outputWidth];
      }

      try {
        let args = [
          "-ss", startTimeStr,                        // Start time
          "-i", "input",                              // Input file
          "-t", durationTimeStr,                      // Duration
          "-s", `${outputWidth}x${outputHeight}`,     // Resolution
          "-c:v", "libx264",                          // Video codec (re-encode with H.264)
          "-preset", "veryfast",                      // Encoding speed preset
          // "-crf", "23",                               // Constant Rate Factor (balance between quality and file size)
          // "-b:v", "2M",                               // Video bitrate
          // "-pix_fmt", "yuv420p",                      // Pixel format
          // "-g", "30"                                  // GOP size (keyframe interval)
        ];

        if (mainStore.isMuted) {
          args.push("-an");   // No audio
        } else {
          args.push(
            "-c:a", "aac",    // Audio codec
            "-b:a", "128k"    // Audio bitrate
          );
        }

        if (videoRotation !== 0) {
          const rotationFilter = `transpose=${getTransposeValue(
            videoRotation
          )}`;
          args.push("-vf", rotationFilter);
        }

        ffmpeg.on("progress", progressListener);

        cancelRef.current = new AbortController();

        const newFile = await ffmpeg.exec(
          file!,
          args,
          cancelRef.current.signal
        );

        if (!cancelRef.current.signal.aborted) {
          const dataURL = URL.createObjectURL(newFile);

          if (isDownload) {
            const brandDetails = JSON.parse(
              localStorage.getItem("brandDetails") || "{}"
            );
            const brandName = brandDetails?.name || "WDF";
            const vn = `${brandName}-${moment().toISOString()}.mp4`;
            await downloadBlob(dataURL, vn);
          } else {
            // isPost
            setPostModalVisible(true);
            setPostVideoUrl(dataURL);
          }

          if (DEBUG_LOGS) {
            const endTime = performance.now();
            const processingTime = (endTime - startTime) / 1000; // Convert to seconds
            console.log(`Video processing time: ${processingTime.toFixed(2)} segundos`);
          }
        }
      } catch (error) {
        if (error.name === "AbortError") {
          // console.log('Operation cancelled');
        } else {
          console.error(
            `Error ${isDownload ? "downloading" : "posting"} the video:`,
            error
          );
        }
      } finally {
        ffmpeg.off("progress", progressListener);
        runInAction(() => {
          actionStatus(false);
          setProcessProgress(0);
          cancelRef.current = null;
        });
      }
    },
    [videoRotation, setProcessProgress]
  );

  const handlePost = useCallback(
    () => processVideo(false, isDogActive),
    [processVideo, isDogActive]
  );
  const handleDownload = useCallback(
    () => processVideo(true, isDogActive),
    [processVideo, isDogActive]
  );

  const cancelOperation = useCallback(async () => {
    if (cancelRef.current) {
      cancelRef.current.abort();
      mainStore.ffmpeg.cancel();

      runInAction(() => {
        setPostStatus(false);
        setDownloadStatus(false);
        setProcessProgress(0);
        cancelRef.current = null;
      });
    }
  }, []);

  return {
    handlePost,
    handleDownload,
    cancelOperation,
    postStatus,
    downloadStatus,
    postVideoUrl,
    setPostVideoUrl
  };
};
