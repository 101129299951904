import {useLazyQuery, useQuery} from "@apollo/client";
import {GET_ALL_VIDEO} from "../../../../shared/graphQL/video";
import {memo, useEffect, useRef, useState} from "react";
import {
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Typography
} from "@mui/material";
import {Box, styled} from "@mui/system";
import {useNavigate} from "react-router";
import momentTimeZone from "moment-timezone";
import {parseVideoPresets} from "../../../../shared/utilities/helpers";

const HoverCard = styled(Card)({
  cursor: "pointer",
  width: "99%",
  marginBottom: 8
});

const CardConventContainer = styled(CardContent)({
  padding: "5px 5px",
  ":last-child": {
    paddingBottom: "5px"
  }
});

function VideoList({dogName}: {dogName: string}) {
  const LIMIT = 40;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [products, setProducts] = useState<any>([]);
  const [getAllVideo, {data, loading}] = useLazyQuery(GET_ALL_VIDEO);
  const listInnerRef = useRef();

  const {data: initialData} = useQuery(GET_ALL_VIDEO, {
    variables: {
      input: {
        page: 1,
        limit: LIMIT,
        dog_name: dogName
      }
    }
  });

  const loadData = () => {
    if (currentPage < totalPage) {
      getAllVideo({
        variables: {
          input: {
            page: currentPage + 1,
            limit: LIMIT,
            dog_name: dogName
          }
        }
      });

      setCurrentPage((page) => page + 1);
    }
  };

  useEffect(() => {
    if (initialData) {
      setProducts(initialData?.findAllVideo?.videos);
      setTotalPage(initialData?.findAllVideo?.pages);
    }
  }, [initialData]);

  useEffect(() => {
    if (data) {
      setProducts((prev) => [...prev, ...data?.findAllVideo?.videos]);
    }
  }, [data]);

  const navigate = useNavigate();
  const handleClick = (key: string) => {
    navigate(`/edit/${key}`);
  };

  useEffect(() => {
    if (data) {
      setProducts((prev) => [...prev, ...data?.findAllVideo?.videos]);
    }
  }, [data]);

  const onScroll = () => {
    if (listInnerRef.current) {
      const {scrollTop, scrollHeight, clientHeight} = listInnerRef.current;

      const SCROLL_HEIGHT = scrollHeight - 50;

      if (scrollTop + clientHeight >= SCROLL_HEIGHT) {
        loadData();
      }
    }
  };

  return (
    <>
      <Box>
        <Typography variant="h5" component={"div"} fontSize={16} pb={1}>
          {dogName} Videos
        </Typography>
      </Box>

      <div
        style={{height: "700px", overflowX: "hidden", overflowY: "scroll"}}
        onScroll={onScroll}
        ref={listInnerRef}
      >
        <Box display={"flex"} flexDirection={"column"} alignItems={"flex-end"}>
          {products.map((product: any) => {
            const videoPresets = parseVideoPresets(product.video_presets);
            const rotation = videoPresets?.rotation || 0;
            const isVertical = rotation === 90 || rotation === 270;

            return (
              <HoverCard
                onClick={() => handleClick(product?._id)}
                key={product?._id}
              >
                <Box
                  sx={{
                    width: "100%",
                    paddingTop: isVertical ? "80%" : "56.25%",
                    position: "relative",
                    overflow: "hidden"
                  }}
                >
                  <CardMedia
                    component="img"
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: isVertical ? "70%" : "100%",
                      transform: `rotate(${rotation}deg)`,
                      transformOrigin: "center",
                      objectFit: "cover"
                    }}
                    image={product.thumbnail_url}
                    alt={product.dog_name}
                  />
                </Box>

                <CardConventContainer>
                  <Typography
                    color="text.primary"
                    variant="subtitle1"
                    component="div"
                  >
                    {momentTimeZone
                      .utc(product?.time)
                      .format("MMM Do YYYY, h:mm:ss a")}
                  </Typography>
                  <Typography
                    color="text.primary"
                    variant="subtitle1"
                    component="div"
                  >
                    {product?.event}
                  </Typography>
                </CardConventContainer>
              </HoverCard>
            );
          })}

          {loading && (
            <Box py={1} margin="auto">
              <CircularProgress size={20} />
            </Box>
          )}
        </Box>
      </div>
    </>
  );
}
const areEqual = (prevProps: any, nextProps: any) => {
  return prevProps.dogName === nextProps.dogName;
};

export default memo(VideoList, areEqual);
