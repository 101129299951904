// web-react-admin-lavabee\src\modules\videos\VideoEditor\index.tsx
import React, {useCallback, useEffect, useRef} from "react";
import {observer} from "mobx-react-lite";
import {VideoCanvas} from "./components/VideoCanvas";
import {ControlButtons} from "./components/ControlButtons";
import {Timeline} from "./components/Timeline";
import {ClipDuration} from "./components/ClipDuration";
// import {useKeyboardShortcuts} from "./hooks/useKeyboardShortcuts";
import {useVideoProgress} from "./hooks/useVideoProgress";
import {useVideoControls} from "./hooks/useVideoControls";
import {useTimelineDrag} from "./hooks/useTimelineDrag";
import styles from "./styles/VideoEditor.module.scss";
import {mainStore} from "./main";

interface VideoEditorProps {
  video: HTMLVideoElement;
  time?: [number, number];
  onChange: (time: [number, number]) => void;
}

const VideoEditorComponent: React.FC<VideoEditorProps> = ({
  video,
  time = [0, video.duration],
  onChange,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const timelineRef = useRef<HTMLDivElement>(null);

  const {
    playing,
    togglePlayPause,
    isFullscreen,
    toggleFullscreen,
    resetToOriginalPresets
  } = useVideoControls(video, containerRef);
  const {currentTime, progress, setProgress, setCurrentTime} =
    useVideoProgress(video);
  const {dragProps, dragState, ignoreTimeUpdatesRef} = useTimelineDrag({
    video,
    time: mainStore.transform.time || [0, video.duration],
    onChange,
    timelineRef,
    setCurrentTime,
    MIN_DURATION: 1
  });

  // useKeyboardShortcuts({ togglePlayPause });

  // Sync video muted state with mainStore
  useEffect(() => {
    video.muted = mainStore.isMuted;
  }, [video, mainStore.isMuted]);

  const handleTimeUpdate = useCallback(
    (newCurrentTime: number, newProgress: number) => {
      setCurrentTime(newCurrentTime);
      setProgress(newProgress);
    },
    [setCurrentTime, setProgress]
  );

  const handleDoubleClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      if (e.target instanceof HTMLCanvasElement) {
        toggleFullscreen();
      }
    },
    [toggleFullscreen]
  );

  return (
    <div
      ref={containerRef}
      className={`${styles.videoEditor} ${!playing ? styles.paused : ""} ${
        isFullscreen ? styles.fullscreen : ""
      }`}
      onDoubleClick={handleDoubleClick}
    >
      <VideoCanvas
        video={video}
        onClick={togglePlayPause}
        onTimeUpdate={handleTimeUpdate}
        ignoreTimeUpdatesRef={ignoreTimeUpdatesRef}
      />
      <ControlButtons
        playing={playing}
        isFullscreen={isFullscreen}
        togglePlayPause={togglePlayPause}
        resetVideo={resetToOriginalPresets}
        toggleFullscreen={toggleFullscreen}
      />
      <Timeline
        ref={timelineRef}
        video={video}
        currentTime={currentTime}
        progress={progress}
        dragProps={dragProps}
        dragState={dragState}
      />
      <ClipDuration start={time[0]} end={time[1]} />
    </div>
  );
};

export const VideoEditor = observer(VideoEditorComponent);
