// web-react-admin-lavabee\src\modules\videos\EditVideo\hooks\useVideoMetadata.ts
import { useMemo } from 'react';
import momentTimeZone from "moment-timezone";

export const useVideoMetadata = (videoData: any) => {
  const metadata = useMemo(() => {
    if (!videoData) return null;

    const { dog_name, location, rfid, time, event } = videoData.findOneVideo;

    return {
      dogName: dog_name,
      location,
      rfid: rfid || "No RFID",
      utcTime: momentTimeZone.utc(time).format("MMM Do YYYY, h:mm:ss a"),
      dallasTime: momentTimeZone.utc(time).tz("America/Chicago").format("MMM Do YYYY, h:mm:ss a"),
      event
    };
  }, [videoData]);

  return { metadata };
};