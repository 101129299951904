// web-react-admin-lavabee/src/modules/videos/SuccessModal.tsx
import React from 'react';
import { Box, DialogContentText, Typography, Button } from "@mui/material";
import DialogComponent from "../../shared/components/Dialog";
import TransactionSubmitted from "../../assets/images/clock.gif";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface Props {
  isVisible: boolean;
  onClose: () => void;
  title: string;
  message: string;
  isError?: boolean;
  isPost?: boolean;
}

const SuccessModalContent: React.FC<{ title: string; message: string; isError?: boolean; isPost?: boolean; onClose: () => void }> = ({ title, message, isError, isPost, onClose }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <DialogContentText
        id="alert-dialog-description"
        sx={{ m: 2, fontWeight: 600, textAlign: "center" }}
      >
        {isPost ? (
          <img src={TransactionSubmitted} alt="Transaction Submitted" />
        ) : isError ? (
          <ErrorIcon color="error" style={{ fontSize: 60 }} />
        ) : (
          <CheckCircleIcon color="success" style={{ fontSize: 60 }} />
        )}

        <Typography sx={{ fontSize: "24px", fontWeight: 700, mt: 2 }}>
          {title}
        </Typography>

        <Typography sx={{ fontSize: "16px", fontWeight: 500, mt: 1 }}>
          {message}
        </Typography>
      </DialogContentText>
      <Button 
        fullWidth
        variant="outlined"
        color="primary"
        onClick={onClose} 
        sx={{ mt: 3, mb: 2 }}
      >
        Close
      </Button>
    </Box>
  );
};

function SuccessModal({ isVisible, onClose, title, message, isError = false, isPost = false }: Props) {
  return (
    <DialogComponent
      open={isVisible}
      handleClose={onClose}
      content={<SuccessModalContent title={title} message={message} isError={isError} isPost={isPost} onClose={onClose} />}
    />
  );
}

export default SuccessModal;